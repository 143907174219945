import { FETCH_STATS } from '../actions/types'
import typeToReducer from 'type-to-reducer'

const initialState = {
  numberOfGuestsThisWeek: 0,
  numberOfGuestsLastWeek: 0,
  usersWithinAWeek: [],
  lastUpdatedAt: new Date()
}

export default typeToReducer({
  [FETCH_STATS]: {
    FULFILLED: (state, action) => ({
      ...state,
      lastUpdatedAt: new Date(),
      ...action.payload
    })
  }
}, initialState)
