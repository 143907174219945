import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { Button, Switch, Typography, withStyles, TextField } from '@material-ui/core'
import apiClient from '../api/ApiClient'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const styles = theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  addButton: {
    margin: theme.spacing(3, 0)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 1300
  },
  buttonsContainer: {
    display: 'flex',
    marginLeft: 'auto'
  },
  usersLabel: {
    marginBottom: theme.spacing(2)
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  pageButtonsContainer: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  textInput: {
    width: 100
  },
  formControl: {
    width: 200
  }
})

const CrawledEvent = withStyles(styles)(({ classes, event, removeFromList, showPending }) => {
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState(event.categoryObj.id)
  const [extraAttendees, setExtraAttendees] = useState(event.extraAttendees)
  const [extraLikes, setExtralikes] = useState(event.extraLikes)
  const categories = useSelector(state => state.data.categories)

  const deleteEvent = async () => {
    setLoading(true)
    await apiClient.crawledEvents.delete(event.id)
    removeFromList(event.id)
    setLoading(false)
  }

  const approve = async () => {
    setLoading(true)
    await patchEvent()
    await apiClient.crawledEvents.approve(event.id)
    removeFromList(event.id)
    setLoading(false)
  }

  const patchEvent = async () => {
    if (isNaN(parseInt(extraAttendees))) {
      window.alert('extraAttendees needs to be a valid integer')
      return
    }
    if (isNaN(parseInt(extraLikes))) {
      window.alert('extraLikes needs to be a valid integer')
      return
    }
    setLoading(true)
    try {
      await apiClient.events.patch(event.id, { extraAttendees: parseInt(extraAttendees), extraLikes: parseInt(extraLikes), categoryId: category })
    } catch (e) {
      window.alert(`something went wrong: ${e}`)
    }
    setLoading(false)
  }

  return (
    <div className={classes.eventContainer}>
      <Typography style={{ width: 60 }}>{event.id}</Typography>
      <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{event?.name?.substring(0, 65)}{event?.name?.length > 65 && '...'}</Typography>
      <div className={classes.buttonsContainer}>
        <TextField
          className={classes.textInput}
          onChange={e => setExtraAttendees(e.target.value)}
          value={extraAttendees}
          label='+attendees'
          variant='outlined'
        />
        <TextField
          className={classes.textInput}
          onChange={e => setExtralikes(e.target.value)}
          value={extraLikes}
          label='+likes'
          variant='outlined'
        />
        <FormControl variant='outlined' className={classes.formControl}>
          <Select
            value={category}
            onChange={e => setCategory(e.target.value)}
          >
            {categories.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)}
          </Select>
        </FormControl>
        <Button href={`${process.env.REACT_APP_RSVP_LINK}${event.code}`} target='_blank'>OPEN</Button>
        <Button href={`${process.env.REACT_APP_RSVP_LINK.slice(0, -2)}/edit/basics/${event.code}`} target='_blank'>EDIT</Button>
        <Button disabled={loading} style={{ color: 'red' }} onClick={deleteEvent}>DELETE</Button>
        {showPending ?
          <Button disabled={loading} color='secondary' onClick={approve}>APPROVE</Button> :
          <Button disabled={loading} onClick={patchEvent}>SAVE</Button>}
      </div>
    </div>
  )
})

const PAGE_SIZE = 15

const Crawled = ({ classes }) => {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)

  const [showPending, setShowPending] = useState(true)
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)

  const getEvents = showPending ? apiClient.crawledEvents.getPending : apiClient.crawledEvents.getApproved

  const eventsOfPage = events.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)

  const fetchCrawledEvents = async (page) => {
    setLoading(true)
    const response = await getEvents(page, PAGE_SIZE)
    setEvents([...events, ...response])
    if (response.length < PAGE_SIZE) setHasMore(false)
    setLoading(false)
  }

  const removeFromList = (id) => {
    setEvents(events.filter(e => e.id !== id))
  }

  const onChangeShowPending = (e) => {
    setShowPending(e.target.checked)
    setEvents([])
    setHasMore(true)
    setPage(0)
  }

  useEffect(() => {
    fetchCrawledEvents(0)
  }, [showPending])

  const nextPage = () => {
    setPage(page + 1)
    if (events.length < (page + 2) * PAGE_SIZE) fetchCrawledEvents(page + 1)
  }

  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item xs={12}>
        <div className={classes.container}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Crawled events
          </Typography>
          <div className={classes.switchContainer}>
            APPROVED
            <Switch checked={showPending} onChange={onChangeShowPending} />
            PENDING
          </div>
          {loading &&
            <Typography variant='body1' className={classes.usersLabel}>
              Loading...
            </Typography>}
          {eventsOfPage.map(event => (
            <CrawledEvent key={event.id} event={event} removeFromList={removeFromList} showPending={showPending} />
          ))}
          <div className={classes.pageButtonsContainer}>
            <Button onClick={() => setPage(page - 1)} disabled={page < 1}>PREVIOUS</Button>
            <Button onClick={nextPage} disabled={!hasMore}>NEXT</Button>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Crawled)
