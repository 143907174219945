import apiClient from '../api/ApiClient'
import {
  CHECK_EMAIL, CLOSE_USER_FOLLOWING, CLOSE_USER_PROFILE,
  FETCH_CATEGORIES,
  FETCH_COMING_UP,
  FETCH_EVENTS,
  FETCH_GUESTS,
  FETCH_ONLINE_ORGANISATIONS,
  FETCH_ONLINE_USERS,
  FETCH_STATS,
  FETCH_USERS, OPEN_USER_FOLLOWING, OPEN_USER_PROFILE,
  PATCH_ORGANISATION, PIN_PAGE,
  RECEIVE_ME,
  REFRESH_HEADER_IMAGES,
  SIGN_IN,
  SIGN_OUT,
  SUBMIT_TOKEN,
  TOGGLE_DRAWER
} from './types'
import { history } from '../config/store'

export const getMe = () => async dispatch => {
  try {
    const user = await apiClient.admin.getMe()
    dispatch(receiveMe(user))
  } catch (e) {
    if (e.status === 403) {
      history.replace('/signIn')
    }
  }
}

export const receiveMe = user => async dispatch => {
  dispatch({
    type: RECEIVE_ME,
    user
  })
  dispatch(fetchCategories())
}

export const signIn = email => async dispatch => {
  dispatch({
    type: SIGN_IN
  })
  await apiClient.admin.signIn(email)
  dispatch({
    type: CHECK_EMAIL
  })
}

export const verifyEmail = (email, token) => async dispatch => {
  try {
    dispatch({
      type: SUBMIT_TOKEN
    })
    const user = await apiClient.admin.submitEmailToken(email, token)
    dispatch(receiveMe(user))
    history.push('/')
  } catch (e) {
    console.warn('Email token submit not succeeded', e)
  }
}

export const signOut = () => async dispatch => {
  await apiClient.admin.signOut()
  dispatch({
    type: SIGN_OUT
  })
  dispatch(getMe())
}

export const fetchCategories = () => ({
  type: FETCH_CATEGORIES,
  payload: apiClient.categories.get()
})

export const fetchStats = () => ({
  type: FETCH_STATS,
  payload: apiClient.stats.get()
})

export const fetchEvents = () => ({
  type: FETCH_EVENTS,
  payload: apiClient.events.get()
})

export const fetchComingUp = () => ({
  type: FETCH_COMING_UP,
  payload: apiClient.events.comingUp()
})

export const fetchGuests = () => ({
  type: FETCH_GUESTS,
  payload: apiClient.guests.get()
})

export const fetchUsers = () => ({
  type: FETCH_USERS,
  payload: apiClient.users.get()
})

export const fetchOnlineUsers = () => ({
  type: FETCH_ONLINE_USERS,
  payload: apiClient.onlineUsers.get()
})

export const fetchOnlineOrganisations = () => ({
  type: FETCH_ONLINE_ORGANISATIONS,
  payload: apiClient.organisations.get()
})

export const toggleDrawerOpen = () => ({
  type: TOGGLE_DRAWER
})

export const patchOrganisation = (id, organisation) => ({
  type: PATCH_ORGANISATION,
  payload: apiClient.organisations.patch(id, organisation)
})

export const refreshHeaderImagesCache = () => ({
  type: REFRESH_HEADER_IMAGES,
  payload: apiClient.refresh.headerImages()
})

export const openUserProfile = (userId) => ({
  type: OPEN_USER_PROFILE,
  payload: userId
})

export const closeUserProfile = () => ({
  type: CLOSE_USER_PROFILE
})

export const openUserFollowing = (userId) => ({
  type: OPEN_USER_FOLLOWING,
  payload: userId
})

export const closeUserFollowing = () => ({
  type: CLOSE_USER_FOLLOWING
})

export const pinPage = (page) => ({
  type: PIN_PAGE,
  payload: page
})
