import {
  CLOSE_USER_FOLLOWING,
  CLOSE_USER_PROFILE,
  OPEN_USER_FOLLOWING,
  OPEN_USER_PROFILE, PIN_PAGE,
  TOGGLE_DRAWER
} from '../actions/types'
import typeToReducer from 'type-to-reducer'

const initialState = {
  drawerOpen: false,
  userDialog: {
    open: false,
    userId: 0
  },
  followingDialog: {
    open: false,
    userId: 0
  },
  pinnedPage: null
}

export default typeToReducer({
  [TOGGLE_DRAWER]: state => ({
    ...state,
    drawerOpen: !state.drawerOpen
  }),
  [OPEN_USER_PROFILE]: (state, action) => ({
    ...state,
    userDialog: {
      open: true,
      userId: action.payload
    }
  }),
  [CLOSE_USER_PROFILE]: state => ({
    ...state,
    userDialog: {
      open: false,
      userId: 0
    }
  }),
  [OPEN_USER_FOLLOWING]: (state, action) => ({
    ...state,
    followingDialog: {
      open: true,
      userId: action.payload
    }
  }),
  [CLOSE_USER_FOLLOWING]: state => ({
    ...state,
    followingDialog: {
      open: false,
      userId: 0
    }
  }),
  [PIN_PAGE]: (state, action) => ({
    ...state,
    pinnedPage: action.payload
  })
}, initialState)
