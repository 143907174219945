import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'
import FeedEvent from './FeedEvent'
import { Check } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
  root: {
  },
  reason: {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    padding: theme.spacing(2, 2, 0, 2),
    flexWrap: 'wrap'
  },
  divider: {
    height: 1,
    margin: theme.spacing(2, -2),
    background: theme.palette.grey.main
  },
  eventType: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2, 2, 2),
    gap: theme.spacing(0.5)
  },
  topContainer: {
    display: 'flex',
    alignItems: 'top'
  },
  seenIcon: {
    marginLeft: 'auto',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}))

const FeedItem = ({ item }) => {
  const classes = useStyles()
  const event = item.content.events.data[0]
  const accounts = item.content.accounts.data
  return (
    <Paper className={classes.root}>
      <div className={classes.topContainer}>
        <div className={classes.reason}>
          <Typography variant='subtitle2'>{'(' + item.id + ') ' + item.type + ' · '}</Typography>
          {accounts.map((account, index) => (
            <Typography variant='body2' key={account.model.id}>
              {(account.model.user ? account.model.user.firstName : account.model.name) + (index === account.length - 1 ? '' : ',')}
            </Typography>
          ))}
        </div>
        {item.seen && (
          <Tooltip title='Seen'>
            <Check color='secondary' className={classes.seenIcon} />
          </Tooltip>
        )}
      </div>
      {/* <div className={classes.divider} />
      <Typography className={classes.eventType} variant='subtitle2'>{item.content.type}</Typography> */}
      {event && <FeedEvent event={event} />}
      <div className={classes.divider} />
      {event && (
        <div className={classes.infoContainer}>
          {event.userGuest?.status === 'INTERESTED' && <Typography variant='caption'>Liked by user</Typography>}
          <Typography variant='caption'>{event.hype.hypeScore + ' Hype Score'}</Typography>
          <Typography variant='caption'>{event.hype.hypedBy.count + ' Hypers'}</Typography>
        </div>
      )}
    </Paper>
  )
}

export default (FeedItem)
