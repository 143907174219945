import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  CircularProgress, IconButton,
  TextField,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import UserFeed from '../components/Feed/UserFeed'
import { useParams } from 'react-router'
import UserSuggestions from '../components/Suggestions/UserSuggestions'
import { useDispatch } from 'react-redux'
import { openUserProfile } from '../actions'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  usersLabel: {
    marginBottom: theme.spacing(2)
  },
  switchContainer: {
    display: 'flex'
  },
  closeButton: {
    cursor: 'pointer',
    margin: theme.spacing(0, 2, 2, 2)
  },
  titleContainer: {
    display: 'flex',
    gap: theme.spacing(2)
  }
}))

const Suggestions = () => {
  const classes = useStyles()
  const [findId, setFindId] = useState()
  const [show, setShow] = useState(false)
  const { userId: queryId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (queryId) {
      setFindId(queryId)
      setShow(true)
    }
  }, [queryId])

  const findFeed = () => {
    setShow(true)
  }

  return (
    <div className={classes.root}>
      {!show && (
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <Typography variant='subtitle1' className={classes.usersLabel}>
              Show a users feed suggestions
            </Typography>
          </div>
          <div className={classes.switchContainer}>
            <TextField
              className={classes.textInput}
              onChange={e => setFindId(e.target.value)}
              value={findId}
              label='User ID'
              variant='outlined'
              type='number'
            />
            <Button onClick={findFeed}>SHOW</Button>
          </div>
        </div>
      )}
      {show && (
        <div>
          <IconButton
            aria-label='remove feed'
            edge='start'
            onClick={() => setShow(false)}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>

          <UserSuggestions userId={findId} />
        </div>
      )}
    </div>
  )
}

export default Suggestions
