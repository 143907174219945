import {
  FETCH_COMING_UP,
  FETCH_EVENTS,
  FETCH_GUESTS,
  FETCH_ONLINE_ORGANISATIONS,
  FETCH_ONLINE_USERS,
  FETCH_USERS,
  FETCH_CATEGORIES,
  PATCH_ORGANISATION
} from '../actions/types'
import typeToReducer from 'type-to-reducer'
import _ from 'underscore'

const initialState = {
  users: [],
  onlineUsers: [],
  onlineOrganisations: [],
  events: [],
  comingUp: [],
  guests: [],
  categories: []
}

export default typeToReducer({
  [FETCH_USERS]: {
    FULFILLED: (state, action) => ({
      ...state,
      users: action.payload
    })
  },
  [FETCH_ONLINE_USERS]: {
    FULFILLED: (state, action) => ({
      ...state,
      onlineUsers: action.payload
    })
  },
  [FETCH_ONLINE_ORGANISATIONS]: {
    FULFILLED: (state, action) => ({
      ...state,
      onlineOrganisations: action.payload
    })
  },
  [PATCH_ORGANISATION]: {
    FULFILLED: (state, action) => ({
      ...state,
      onlineOrganisations: _.map(state.onlineOrganisations, organisation => organisation.id === action.payload.id ? action.payload : organisation)
    })
  },
  [FETCH_EVENTS]: {
    FULFILLED: (state, action) => ({
      ...state,
      events: action.payload
    })
  },
  [FETCH_COMING_UP]: {
    FULFILLED: (state, action) => ({
      ...state,
      comingUp: action.payload
    })
  },
  [FETCH_GUESTS]: {
    FULFILLED: (state, action) => ({
      ...state,
      guests: action.payload
    })
  },
  [FETCH_CATEGORIES]: {
    FULFILLED: (state, action) => ({
      ...state,
      categories: action.payload
    })
  }
}, initialState)
