import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Paper, Typography, withStyles } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import AccountIcon from '../media/account-group.svg'
import * as classNames from 'classnames'
import MessageReplyIcon from '../media/message-reply.svg'
import HiddenIcon from '../media/eye-off.svg'
import { connect } from 'react-redux'
import { fetchEvents } from '../actions'
import { getEventStateName, getEventTypeName } from '../util'
import apiClient from '../api/ApiClient'
import useKeysetPagination from '../hooks/useKeysetPagination'

const styles = theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  usersItem: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: 56,
    overflowX: 'auto'
  },
  usersLabel: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit
  },
  tableCellRoot: {
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    maxWidth: '150px'
  },
  clickableCell: {
    cursor: 'pointer'
  },
  cellIcon: {
    verticalAlign: 'bottom',
    marginRight: theme.spacing.unit * 2
  },
  avatar: {
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
    borderRadius: 1000,
    verticalAlign: 'bottom',
    marginRight: theme.spacing.unit * 2
  },
  shiftDown: {
    bottom: -2,
    position: 'relative'
  }
})

const Events = ({ classes }) => {
  const { data: events, tablePaginationEl } = useKeysetPagination(apiClient.events.get, event => event.createdAt)

  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item xs={12}>
        <Paper elevation={2} className={classes.usersItem}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            New Events
          </Typography>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableCellRoot }}>Event (ID)</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>User (ID)</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>Date</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>Type</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>State</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>Created</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>Activity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map(event => {
                  const organiser = event.type === 'PUBLIC' ? event.organisation : event.organisers0?.[0]
                  return (
                    <TableRow key={event.id}>
                      <TableCell
                        onClick={() => window.open(process.env.REACT_APP_RSVP_LINK + event.code + '?v=Test')}
                        classes={{ root: classes.tableCellRoot }} className={classes.clickableCell} component='th' scope='row'
                      >
                        {event.name} ({event.id})
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          if (event.organisation) window.open(process.env.REACT_APP_PROFILE_LINK + event.organisation.username)
                        }} classes={{ root: classes.tableCellRoot }} className={event.organisation && classes.clickableCell}
                      >
                        {organiser?.name} ({organiser?.id})
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {moment(event.startDate).format('dd DD MMM HH:mm')}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {getEventTypeName(event.type, event.privacyType)}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {getEventStateName(event.state)}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {moment(event.createdAt).format('dd DD MMM HH:mm')}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {event.guestCount !== 0 &&
                          <Tooltip title='Number of guests' aria-label='Number of guests'>
                            <span>
                              <b>{event.guestCount}</b>
                              <img src={AccountIcon} className={classNames(classes.cellIcon, classes.shiftDown)} />
                            </span>
                          </Tooltip>}
                        {event.messageCount !== 0 &&
                          <Tooltip title='Events as guest' aria-label='Events as guest'>
                            <span>
                              <b>{event.messageCount}</b>
                              <img src={MessageReplyIcon} className={classNames(classes.cellIcon, classes.shiftDown)} />
                            </span>
                          </Tooltip>}
                        {!event.guestsCanSeeGuests &&
                          <Tooltip title='Private guest list' aria-label='Private guest list'>
                            <span>
                              <img src={HiddenIcon} className={classNames(classes.cellIcon, classes.shiftDown)} />
                            </span>
                          </Tooltip>}
                      </TableCell>
                    </TableRow>
                  )
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {tablePaginationEl}
        </Paper>
      </Grid>
    </Grid>
  )
}

function mapStateToProps ({ user }) {
  return {
    authString: user.authString
  }
}

export default connect(mapStateToProps, { fetchEvents })(withStyles(styles)(Events))
