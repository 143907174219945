import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { fetchOnlineUsers, fetchStats } from '../actions'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import UserList from '../components/UserList'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import useKeysetPagination from '../hooks/useKeysetPagination'
import apiClient from '../api/ApiClient'

const styles = theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  whiteSurface: {
    borderRadius: 8,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  highlightedSurface: {
    borderRadius: 8,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up('md')]: {
      transform: 'scale(1.2)',
      transformOrigin: 'center 25%',
      zIndex: 999
    }
  },
  statLabel: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit
  },
  statOnWhiteLabel: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit
  },
  highlightedStatLabel: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit
  },
  statValue: {
    textAlign: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit
  },
  highlightedStatValue: {
    textAlign: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit
  },
  secondaryStat: {
    textAlign: 'center'
  },
  highlightedSecondaryStat: {
    textAlign: 'center'
  },
  onlineUsers: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: '100%',
    overflowX: 'scroll'
  },
  userTimeline: {
    flexGrow: 1,
    height: 64,
    position: 'relative',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  userDot: {
    position: 'absolute',
    top: '20%',
    width: 4,
    height: 4,
    borderRadius: 4,
    marginLeft: -2,
    backgroundColor: theme.palette.secondary.main
  },
  timelineLabelLeft: {
    position: 'absolute',
    left: 0,
    bottom: 0
  },
  timelineLabelTwoFifths: {
    position: 'absolute',
    left: '25%',
    bottom: 0
  },
  timelineLabelThreeFifths: {
    position: 'absolute',
    left: '50%',
    bottom: 0
  },
  timelineLabelFourFifths: {
    position: 'absolute',
    left: '75%',
    bottom: 0
  },
  timelineLabelRight: {
    position: 'absolute',
    right: 0,
    bottom: 0
  }
})

const REFRESH_TIME = 20000

const Home = ({ fetchStats, stats, classes }) => {
  const { data: onlineUsers, tablePaginationEl } = useKeysetPagination(apiClient.onlineUsers.get, user => user.lastOnline, REFRESH_TIME)
  useEffect(() => {
    fetchStats()
  }, [fetchStats])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchStats()
    }, REFRESH_TIME)
    return () => clearInterval(interval)
  }, [])

  const twentyFourHoursAgo = moment().subtract(1, 'days')
  const now = moment()
  const total = 24 * 60 * 60
  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item md={4} xs={12}>
        <Paper elevation={2} className={classes.whiteSurface}>
          <Typography variant='subtitle1' className={classes.statLabel}>
            Active events created this week
          </Typography>
          <Typography variant='h4' color='primary' className={classes.statValue}>
            {stats.numberOfEventsThisWeek}
          </Typography>
          <Typography variant='subtitle1' className={classes.secondaryStat}>
            Last week: {stats.numberOfEventsLastWeek}
          </Typography>
        </Paper>
      </Grid>
      <Grid item md={4} xs={12}>
        <Paper elevation={8} className={classes.highlightedSurface}>
          <Typography variant='subtitle1' className={classes.highlightedStatLabel}>
            Number of RSVPs this week
          </Typography>
          <Typography variant='h4' color='primary' className={classes.highlightedStatValue}>
            {stats.numberOfGuestsThisWeek}
          </Typography>
          <Typography variant='subtitle1' className={classes.highlightedSecondaryStat}>
            Last week: {stats.numberOfGuestsLastWeek}
          </Typography>
        </Paper>
      </Grid>
      <Grid item md={4} xs={12}>
        <Paper elevation={2} className={classes.whiteSurface}>
          <Typography variant='subtitle1' className={classes.statLabel}>
            New active users this week
          </Typography>
          <Typography variant='h4' color='primary' className={classes.statValue}>
            {stats.numberOfUsersThisWeek}
          </Typography>
          <Typography variant='subtitle1' className={classes.secondaryStat}>
            Last week: {stats.numberOfUsersLastWeek}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={2} className={classNames(classes.whiteSurface, classes.onlineUsers)}>
          <Typography variant='subtitle1' className={classes.statOnWhiteLabel}>
            Online users in the last 24 hours ({stats.usersLast24Hours?.length})
          </Typography>
          <div className={classes.userTimeline}>
            {stats.usersLast24Hours?.map(user => {
              return (
                <Tooltip key={user.lastOnline} title={user.name} aria-label={user.name}>
                  <div
                    style={{
                      left: (moment(user.lastOnline).unix() - twentyFourHoursAgo.unix()) / total * 100 + '%'
                    }} className={classes.userDot}
                  />
                </Tooltip>
              )
            })}
            <div className={classes.timelineLabelLeft}>
              {twentyFourHoursAgo.format('HH:mm')}
            </div>
            <div className={classes.timelineLabelTwoFifths}>
              {twentyFourHoursAgo.add(4.8, 'hours').format('HH:mm')}
            </div>
            <div className={classes.timelineLabelThreeFifths}>
              {twentyFourHoursAgo.add(4.8, 'hours').format('HH:mm')}
            </div>
            <div className={classes.timelineLabelFourFifths}>
              {twentyFourHoursAgo.add(4.8, 'hours').format('HH:mm')}
            </div>
            <div className={classes.timelineLabelRight}>
              {now.format('HH:mm')}
            </div>
          </div>
          <UserList users={onlineUsers} />
          {tablePaginationEl}
        </Paper>
      </Grid>
    </Grid>
  )
}

function mapStateToProps ({ stats, data }) {
  return {
    stats,
    onlineUsers: data.onlineUsers
  }
}

export default connect(mapStateToProps, { fetchStats, fetchOnlineUsers })(withStyles(styles)(Home))
