import React, { useState } from 'react'
import { Button, CircularProgress, withStyles } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import InfiniteScroll from 'react-infinite-scroller'
import apiClient from '../api/ApiClient'
import { history } from '../config/store'
import { useDispatch } from 'react-redux'
import { openUserProfile } from '../actions'

const styles = theme => ({
  root: {
    height: '700px',
    width: '600px',
    maxWidth: 'unset'
  },
  followersList: {
    overflow: 'auto'
  },
  profile: {
    padding: theme.spacing(1, 1, 1, 0),
    display: 'flex',
    alignItems: 'center'
  },
  profileAvatar: {
    width: 32,
    height: 32,
    borderRadius: '100px',
    marginRight: theme.spacing(2)
  },
  feedButton: {
    marginLeft: 'auto'
  },
  notifsButton: {
    marginLeft: theme.spacing(1)
  }
})

const FOLLOWER_PAGE_SIZE = 10

function FollowersDialog ({ classes, organisation, open, handleClose }) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [followers, setFollowers] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const ref = React.useRef()

  React.useEffect(() => {
    if (!open) {
      setFollowers([])
      setHasMore(true)
    }
  }, [open, organisation])

  const loadMore = async () => {
    if (organisation !== null) {
      setLoading(true)
      const page = Math.floor(followers.length / FOLLOWER_PAGE_SIZE)
      // dispatch(fetchActivity(page, FOLLOWER_PAGE_SIZE))
      const newFollowers = await apiClient.organisations.getFollowers(organisation.id, page, FOLLOWER_PAGE_SIZE)
      setHasMore(newFollowers.length === FOLLOWER_PAGE_SIZE)
      setFollowers(prev => [...prev, ...newFollowers])
      // eslint-disable-next-line promise/param-names
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      classes={{
        paper: classes.root
      }}
    >
      <DialogTitle id='alert-dialog-title'>Followers</DialogTitle>
      <DialogContent ref={ref}>
        <div className={classes.followersList}>
          <InfiniteScroll
            pageStart={-1}
            loadMore={loadMore}
            hasMore={((followers.length / FOLLOWER_PAGE_SIZE) % 1 === 0 && !loading && hasMore)}
            useWindow={false}
            threshold={10}
            getScrollParent={() => ref.current}
          >
            {followers.map((follower, index) => {
              return (
                <div
                  className={classes.profile}
                  key={index}
                >
                  <img src={follower.avatarUrls?.sm} className={classes.profileAvatar} alt='profile avatar' />
                  {follower.name + ` (${follower.id})`}
                  <Button
                    color='primary'
                    className={classes.feedButton}
                    onClick={() => dispatch(openUserProfile(follower.id))}
                  >
                    Profile
                  </Button>
                </div>
              )
            })}
            {loading && <CircularProgress />}
          </InfiniteScroll>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(FollowersDialog)
