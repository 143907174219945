import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import logo from '../../media/icon.svg'
import { Close, Email, Phone, PhoneIphone } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {

  },
  topSection: {
    display: 'flex',
    alignItems: 'top'
  },
  title: {
  },
  mainContainer: {
    flexGrow: 1
  },
  description: {
    padding: theme.spacing(0, 2)
  },
  divider: {
    height: 1,
    margin: theme.spacing(2, -2),
    background: theme.palette.grey.main
  },
  date: {
    lineHeight: '14px'
  },
  titleContainer: {
    padding: theme.spacing(2, 2, 1, 2),
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'space-between'
  },
  image: {
    width: '40px',
    height: '40px',
    borderRadius: '100px',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    objectFit: 'contain'
  },
  logo: {
    width: '40px',
    height: '40px',
    padding: '8px',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  infoContainer: {
    padding: theme.spacing(0, 2, 2, 2)
  },
  infoItem: {
    display: 'flex',
    alignItems: 'top',
    gap: theme.spacing(2)
  },
  infoText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    marginTop: '2px'
  },
  tertiary: {
    color: theme.palette.tertiary.main
  }
}))

const NotificationItem = ({ item }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <div className={classes.topSection}>
        {item.iconUrl && <img className={classes.image} src={item.iconUrl} alt='image' />}
        {!item.iconUrl && <img className={classes.logo} src={logo} alt='image' />}
        <div className={classes.mainContainer}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title} variant='subtitle1'>
              {item.title ?? item.type + (item.hidden ? ' (Hidden)' : '')}
            </Typography>
            <Typography className={classes.date} color='textSecondary' variant='overline'>
              <b>
                {dayjs(item.createdAt).format('ddd DD/MM HH:mm')}
              </b>
            </Typography>
          </div>
          <Typography className={classes.description} variant='body2' color='textSecondary'>
            {item.description}
          </Typography>
        </div>
      </div>
      <div className={classes.divider} />
      <div className={classes.infoContainer}>
        <div className={classes.infoItem}>
          {item.medium === 'EMAIL' && <Email />}
          {item.medium === 'PUSH' && <PhoneIphone />}
          {(!item.medium || item.medium === 'UNREACHABLE') && <Close className={classes.tertiary} />}
          <div className={classes.infoText}>
            <Typography variant='body2'>{`Medium: ${item.medium}`}</Typography>
            {item.deliveredAt && <Typography variant='body2'>{`Delivered on ${dayjs(item.deliveredAt).format('ddd DD/MM HH:mm')}`}</Typography>}
            {item.openedAt && <Typography variant='body2'>{`Opened on ${dayjs(item.openedAt).format('ddd DD/MM HH:mm')}`}</Typography>}
          </div>
        </div>

      </div>
    </Paper>
  )
}

export default (NotificationItem)
