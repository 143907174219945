import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, Paper, Typography, withStyles, TextField, Divider, Avatar, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { fetchOnlineOrganisations, refreshHeaderImagesCache } from '../actions'
import apiClient from '../api/ApiClient'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const styles = theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  settingsContainer: {
    margin: theme.spacing(2)
  },
  singleSettingContainer: {
    margin: theme.spacing(2, 0)
  },
  userInfoRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  deleteButton: {
    color: 'red'
  }
})

const Settings = ({ classes, refreshHeaderImagesCache }) => {
  const [email, setEmail] = useState('')
  const [user, setUser] = useState(null)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  console.log(user)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const findUserByEmailAddress = async () => {
    let user = null
    try {
      user = await apiClient.users.findByEmailAddress(email)
      setUser(user)
    } catch (e) {
      console.log(e)
    }
  }

  const confirmDeleteUser = async () => {
    if (loading) return
    setLoading(true)
    const response = await apiClient.users.delete(user.id)
    console.log(response)
    setLoading(false)
    handleClose()
    setUser(null)
    setEmail('')
  }

  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item xs={12}>
        <Paper elevation={2} className={classes.settingsContainer}>
          <div className={classes.singleSettingContainer}>
            <Typography variant='subtitle1' className={classes.usersLabel}>
              Settings
            </Typography>
            <Button onClick={refreshHeaderImagesCache}>Refresh header images cache</Button>
          </div>
          <div className={classes.singleSettingContainer}>
            <Typography variant='subtitle1' className={classes.usersLabel}>
              Delete user
            </Typography>
            <TextField label='Email address' value={email} onChange={(e) => setEmail(e.target.value)} />
            <Button onClick={findUserByEmailAddress}>Find</Button>
            <Divider />
            {user &&
              <div className={classes.userInfoContainer}>
                <Typography variant='subtitle2' className={classes.usersLabel}>
                  User:
                </Typography>
                <div className={classes.userInfoRow}>
                  <Avatar src={user.avatarUrls.lg} />
                  <Typography variant='body1'>{user.name}</Typography>
                </div>
                <Button className={classes.deleteButton} onClick={handleClickOpen}>DELETE</Button>
              </div>}
          </div>
        </Paper>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Are you sure you want to delete this user?</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure you want to delete <b>{user?.name}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              Cancel
            </Button>
            <Button className={classes.deleteButton} onClick={confirmDeleteUser} color='inherit'>
              {loading && <CircularProgress />}
              DELETE
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  )
}

function mapStateToProps () {
  return null
}

export default connect(mapStateToProps, { fetchOnlineOrganisations, refreshHeaderImagesCache })(withStyles(styles)(Settings))
