export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const SUBMIT_TOKEN = 'SUBMIT_TOKEN'
export const CHECK_EMAIL = 'CHECK_EMAIL'

export const FETCH_ME = 'FETCH_ME'
export const RECEIVE_ME = 'RECEIVE_ME'

export const FETCH_STATS = 'FETCH_STATS'
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'

export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_ONLINE_USERS = 'FETCH_ONLINE_USERS'
export const FETCH_ONLINE_ORGANISATIONS = 'FETCH_ONLINE_ORGANISATIONS'
export const FETCH_EVENTS = 'FETCH_EVENTS'
export const FETCH_COMING_UP = 'FETCH_COMING_UP'
export const FETCH_GUESTS = 'FETCH_GUESTS'
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'

export const PATCH_ORGANISATION = 'PATCH_ORGANISATION'

export const REFRESH_HEADER_IMAGES = 'REFRESH_HEADER_IMAGES'

export const OPEN_USER_PROFILE = 'OPEN_USER_PROFILE'
export const CLOSE_USER_PROFILE = 'CLOSE_USER_PROFILE'

export const OPEN_USER_FOLLOWING = 'OPEN_USER_FOLLOWING'
export const CLOSE_USER_FOLLOWING = 'CLOSE_USER_FOLLOWING'

export const PIN_PAGE = 'PIN_PAGE'
