import typeToReducer from 'type-to-reducer'
// eslint-disable-next-line import/no-duplicates
import { CHECK_EMAIL, RECEIVE_ME, SIGN_IN, SIGN_OUT, SUBMIT_TOKEN } from '../actions/types'

const initialState = {
  submitting: false,
  requesting: false,
  inEmail: false
}

export default typeToReducer({
  [SIGN_IN]: state => ({
    ...state,
    requesting: true
  }),
  [SIGN_OUT]: state => ({
    ...state,
    requesting: false,
    submitting: false,
    inEmail: false
  }),
  [CHECK_EMAIL]: state => ({
    ...state,
    requesting: false,
    inEmail: true
  }),
  [SUBMIT_TOKEN]: state => ({
    ...state,
    submitting: true
  }),
  [RECEIVE_ME]: state => ({
    ...state,
    submitting: false,
    requesting: false,
    inEmail: false
  })
}, initialState)
