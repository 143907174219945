import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper, TextField, Typography, Button, IconButton, CircularProgress } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers'
import { Clear } from '@material-ui/icons'
import Checkbox from '@material-ui/core/Checkbox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import apiClient from '../api/ApiClient'
import { allCountryCodes, allCountryCodesByCode } from '../constants/countryCodes'
import Resizer from 'react-image-file-resizer'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

export const limitFileSize = (file) => new Promise(resolve => {
  if (!file) resolve(null)
  const format = file.type === 'image/png' ? 'PNG' : 'JPEG'
  Resizer.imageFileResizer(file, 512, 512, format, 100, 0,
    uri => {
      resolve(uri)
    },
    'blob'
  )
})

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    position: 'relative'
  },
  image: {
    height: 200,
    width: 300,
    objectFit: 'cover',
    marginRight: theme.spacing(2)
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 120
  },
  localeInputRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  textInputContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  textInput: {
    marginBottom: theme.spacing(2),
    width: 400
  },
  addEndDateButton: {
    width: 180
  },
  saveButton: {
    position: 'absolute',
    bottom: 16,
    right: 16
  },
  deleteButton: {
    position: 'absolute',
    bottom: 16,
    left: 16
  }
}))

const useDateTimePickerStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(2)
    },
    alignItems: 'center'
  },
  text: {
    width: 100
  },
  datePicker: {
    width: 240,
    marginTop: 0,
    marginBottom: 0
  }
}))

export const DateTimePicker = ({ title, date, setDate, onRemove, label = 'Date' }) => {
  const classes = useDateTimePickerStyles()
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.root}>
        {title &&
          <Typography className={classes.text} variant='subtitle1'>
            {title}
          </Typography>}
        <KeyboardDateTimePicker
          className={classes.datePicker}
          margin='normal'
          ampm={false}
          id='date-picker-dialog'
          label={label}
          format='DD-MM-yyyy HH:mm'
          value={date}
          onChange={setDate}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          variant='outlined'
        />
        {onRemove && <IconButton onClick={onRemove}><Clear /></IconButton>}
      </div>
    </MuiPickersUtilsProvider>
  )
}

const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) { return false }
    for (var prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false
      }
    }
    return true
  }

  // Private
  function isObject (obj) {
    if (typeof obj === 'object' && obj != null) {
      return true
    } else {
      return false
    }
  }
}

const FeaturedItem = ({ featuredItem, reloadPage }) => {
  const classes = useStyles()
  const [currentLocale, setCurrentLocale] = useState('en')
  const [translations, setTranslations] = useState(featuredItem.translations || {})
  const [startDate, setStartDate] = useState(moment(featuredItem.startDate).seconds(0).milliseconds(0))
  const [hasEndDate, setHasEndDate] = useState(featuredItem.endDate != null)
  const [endDate, setEndDate] = useState(featuredItem.endDate != null ? moment(featuredItem.endDate).seconds(0).milliseconds(0) : moment())
  const [countryCodes, setCountryCodes] = useState((featuredItem.countryCodes || []).map(cc => allCountryCodesByCode[cc]))
  const [dark, setDark] = useState(featuredItem.dark ?? true)
  const [loading, setLoading] = useState(false)
  const [imgFile, setImgFile] = useState(null)

  const setDescription = (event) => {
    setTranslations({
      ...translations,
      [currentLocale]: {
        ...translations[currentLocale],
        description: event.target.value
      }
    })
  }

  const setTitle = (event) => {
    setTranslations({
      ...translations,
      [currentLocale]: {
        ...translations[currentLocale],
        title: event.target.value
      }
    })
  }

  const isSaveAble = () => {
    const translationDifferent = !deepEqual(featuredItem.translations, translations)
    const noEndDateDifferent = (featuredItem.endDate == null && hasEndDate) || (featuredItem.endDate != null && !hasEndDate)
    const endDateDifferent = !noEndDateDifferent && hasEndDate && !moment(featuredItem.endDate).seconds(0).milliseconds(0).isSame(endDate)
    const startDateDifferent = !moment(featuredItem.startDate).seconds(0).milliseconds(0).isSame(startDate)
    const darkDifferent = dark !== featuredItem.dark
    return translationDifferent || noEndDateDifferent || endDateDifferent || startDateDifferent || darkDifferent || imgFile != null
  }

  const saveItem = async () => {
    setLoading(true)
    const postObject = {
      startDate,
      endDate,
      translations,
      dark,
      countryCodes: countryCodes.map(cc => cc.code)
    }
    if (featuredItem.id != null) {
      if (!hasEndDate) {
        postObject.setEndDateNull = true
        postObject.endDate = null
      }
      postObject.id = featuredItem.id
      await apiClient.featuredItems.patch(postObject)
      if (imgFile != null) await uploadImage(featuredItem.id)
    } else {
      const newFeaturedItem = await apiClient.featuredItems.post(postObject)
      await uploadImage(newFeaturedItem.id)
    }
    setLoading(false)
  }

  const selectImage = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const limitedImg = await limitFileSize(file)
      // eslint-disable-next-line no-undef
      const limitedFile = new File([limitedImg], file.name)
      setImgFile(limitedFile)
    }
  }

  const uploadImage = async (featuredItemId) => {
    if (!imgFile) {
      window.alert('tried to upload image without an image selected')
      return
    }
    const limitedImg = await limitFileSize(imgFile)
    const uploadUrl = await apiClient.featuredItems.getUploadUrl(featuredItemId, limitedImg.type)
    await fetch(uploadUrl, {
      method: 'PUT',
      header: {
        'Content-Type': 'image/jpeg'
      },
      body: limitedImg
    })
  }

  const deleteItem = async () => {
    if (featuredItem.id != null) {
      await apiClient.featuredItems.delete(featuredItem.id)
    }
    reloadPage()
  }

  return (
    <Paper className={classes.root}>
      <div>
        <img src={imgFile ? URL.createObjectURL(imgFile) : (featuredItem.headerImageKeySignedUrl ?? featuredItem.headerImageUrl)} className={classes.image} />
        <input type='file' onChange={selectImage} />
        <Button className={classes.deleteButton} onClick={deleteItem} variant='outlined' color='primary'>
          Delete
        </Button>
      </div>
      <div className={classes.infoContainer}>
        <Autocomplete
          multiple
          options={allCountryCodes}
          disableCloseOnSelect
          value={countryCodes}
          onChange={(event, newValue) => {
            setCountryCodes(newValue)
          }}
          getOptionLabel={(option) => option.name}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </>
          )}
          style={{ width: 536, marginBottom: 16 }}
          renderInput={(params) => (
            <TextField {...params} variant='outlined' label='Country Codes' placeholder='Search...' />
          )}
        />
        <div className={classes.localeInputRow}>
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel>Locale</InputLabel>
            <Select
              value={currentLocale}
              onChange={e => setCurrentLocale(e.target.value)}
              label='Language'
            >
              <MenuItem value='en'>English</MenuItem>
              <MenuItem value='nl'>Dutch</MenuItem>
            </Select>
          </FormControl>
          <div className={classes.textInputContainer}>
            <TextField
              className={classes.textInput}
              onChange={setTitle}
              value={translations[currentLocale]?.title ?? ''}
              label='Title'
              variant='outlined'
            />
            <TextField
              className={classes.textInput}
              onChange={setDescription}
              value={translations[currentLocale]?.description ?? ''}
              label='Description'
              variant='outlined'
            />
          </div>
        </div>
        <FormControlLabel
          control={<Switch checked={dark} onChange={(event) => setDark(event.target.checked)} name='dark' />}
          label='Dark'
        />
        <DateTimePicker date={startDate} setDate={setStartDate} title='Start date' />
        {hasEndDate ?
          <DateTimePicker date={endDate} setDate={setEndDate} title='End date' onRemove={() => setHasEndDate(false)} /> :
          <Button className={classes.addEndDateButton} color='secondary' variant='outlined' onClick={() => setHasEndDate(true)}>Add end date</Button>}
      </div>
      <Button className={classes.saveButton} disabled={!isSaveAble()} onClick={saveItem} variant='outlined' color='primary'>
        {loading && <CircularProgress size={16} />}
        Save
      </Button>
    </Paper>
  )
}

export default FeaturedItem
