import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, SvgIcon, Typography } from '@material-ui/core'
import VerifiedIcon from '../../svg/CheckDecagram'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(2)
  },
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 100
  },
  title: {
    fontWeight: 500,
    maxWidth: 160,
    marginRight: theme.spacing(0.5)
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  verifiedIcon: {
    width: 16,
    height: 16
  },
  textContainer: {
    marginRight: 'auto'
  }
}))

const SuggestionItem = ({ item }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <img alt='avatar' className={classes.avatar} src={item.model.avatarUrls.lg} />
      <div className={classes.textContainer}>
        <div className={classes.titleContainer}>
          <Typography noWrap className={classes.title} variant='body2'>{item.model.firstName ?? item.model.name}</Typography>
          {item.model.verified && <SvgIcon component={VerifiedIcon} className={classes.verifiedIcon} color='secondary' />}
        </div>
        <Typography color='textSecondary' variant='caption'>{item.type === 'ORGANISATION' ? 'Page' : 'User'}</Typography>
      </div>
      {!!item.model.eventCount && <Typography variant='body2'>{`${item.model.eventCount} events`}</Typography>}
      {!!item.model.followerCount && <Typography variant='body2'>{`${item.model.followerCount} followers`}</Typography>}
      {!!item.model.eventsHosted && <Typography variant='body2'>{`${item.model.eventsHosted} hosted`}</Typography>}
      {!!item.model.friends && <Typography variant='body2'>{`${item.model.friends} friends`}</Typography>}
    </Paper>
  )
}

export default (SuggestionItem)
