import React, { useMemo } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import NavDrawer from './components/NavDrawer'
import requireAuth from './components/composers/requireAuth'
import Home from './routes/Home'
import SignIn from './routes/SignIn'
import Users from './routes/Users'
import Events from './routes/Events'
import ComingUp from './routes/ComingUp'
import Guests from './routes/Guests'
import Crawled from './routes/Crawled'
import CrawledOrg from './routes/CrawledOrg'
import CreateEvent from './routes/CreateEvent'
import Organisations from './routes/Organisations'
import { ThemeProvider } from '@material-ui/core/styles'
import makeTheme from './config/theme'
import { useMediaQuery } from '@material-ui/core'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import initStore, { history } from './config/store'

import { getMe } from './actions'
import Settings from './routes/Settings'
import FeaturedItems from './routes/FeaturedItems'
import Notifications from './routes/Notifications'
import Reports from './routes/Reports'
import Feeds from './routes/Feeds'
import UserNotifications from './routes/UserNotifications'
import Suggestions from './routes/Suggestions'
import UserDialog from './components/UserDialog'
import FollowingDialog from './components/UserProfile/FollowingDialog'
import EventManagement from './routes/EventManagement'
import UserManagement from './routes/UserManagement'

const store = initStore()
store.dispatch(getMe())

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = useMemo(
    () => {
      // return makeTheme(prefersDarkMode)
      return makeTheme(false)
    },
    [prefersDarkMode]
  )

  return (
    <>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
              <Route path='/signIn' />
              <Route component={Header} />
            </Switch>
            <NavDrawer />
            <UserDialog />
            <FollowingDialog />
            <Switch>
              <Route exact path='/' component={requireAuth(Home)} />
              <Route path='/signIn/:email?/:token?' component={SignIn} />
              <Route path='/users' component={requireAuth(Users)} />
              <Route path='/events' component={requireAuth(Events)} />
              <Route path='/feeds/:userId?' component={requireAuth(Feeds)} />
              <Route path='/userNotifications/:userId?' component={requireAuth(UserNotifications)} />
              <Route path='/suggestions/:userId?' component={requireAuth(Suggestions)} />
              <Route path='/comingUp' component={requireAuth(ComingUp)} />
              <Route path='/guests' component={requireAuth(Guests)} />
              <Route path='/onlineOrganisations' component={requireAuth(Organisations)} />
              <Route path='/settings' component={requireAuth(Settings)} />
              <Route path='/featuredItems' component={requireAuth(FeaturedItems)} />
              <Route path='/notifications' component={requireAuth(Notifications)} />
              <Route path='/crawledEvents' component={requireAuth(Crawled)} />
              <Route path='/crawledOrg' component={requireAuth(CrawledOrg)} />
              <Route path='/createEvent' component={requireAuth(CreateEvent)} />
              <Route path='/reports' component={requireAuth(Reports)} />
              <Route path='/eventManagement' component={requireAuth(EventManagement)} />
              <Route path='/userManagement' component={requireAuth(UserManagement)} />
            </Switch>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </>
  )
}

export default App
