import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth'
import user from './user'
import stats from './stats'
import ui from './ui'
import data from './data'

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  user,
  stats,
  ui,
  data
})
