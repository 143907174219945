import React, { Component, useEffect, useState } from 'react'
import icon from '../media/icon.svg'
import { ReactComponent as Word } from '../media/word.svg'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect, useDispatch, useSelector } from 'react-redux'
import { pinPage, signOut, toggleDrawerOpen } from '../actions'
import { Button, Paper, Typography } from '@material-ui/core'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/styles'
import FollowersDialog from './FollowersDialog'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    zIndex: 10,
    top: 0,
    left: 0,
    right: 0,
    maxWidth: 960,
    width: '100%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 56,
    justifyContent: 'space-between'
  },
  menuButton: {
    [theme.breakpoints.up('xl')]: {
      display: 'none'
    }
  },
  left: {
    width: '40%'
  },
  right: {
    width: '40%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  word: {
    height: 16,
    color: theme.palette.type === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.87)'
  },
  onlineDot: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    backgroundColor: theme.palette.green.main,
    borderRadius: 1000,
    marginRight: theme.spacing.unit * 2,
    animationName: 'blink',
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate'
  },
  offlineDot: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    backgroundColor: theme.palette.red.main,
    borderRadius: 1000,
    marginRight: theme.spacing.unit * 2
  },
  signOut: {
    fontWeight: 'bold'
  },
  pinnedPageContainer: {
    display: 'flex',
    alignItems: 'center',
    borderTop: theme.palette.grey.main + ' 1px solid',
    padding: theme.spacing(2, 0),
    gap: theme.spacing(2)
  },
  pinnedAvatar: {
    height: 40,
    width: 40,
    borderRadius: 100
  },
  closeButton: {
    marginLeft: 'auto'
  }
}))

const Header = () => {
  const lastUpdatedAt = useSelector(state => state.stats.lastUpdatedAt)
  const pinnedPage = useSelector(state => state.ui.pinnedPage)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [statusOnline, setStatusOnline] = useState(true)
  const [followersOpen, setFollowersOpen] = useState(false)

  useEffect(() => {
    startTimeOut()
  }, [])

  const startTimeOut = () => {
    setInterval(
      function () {
        checkStatus()
      },
      1000
    )
  }

  const checkStatus = () => {
    const diff = moment(lastUpdatedAt).diff(moment(new Date()))
    if (diff < -20000) {
      setStatusOnline(false)
    } else {
      setStatusOnline(true)
    }
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.mainContainer}>
        <IconButton
          aria-label='open drawer'
          edge='start'
          onClick={() => dispatch(toggleDrawerOpen())}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.left}>
          <img src={icon} height={24} />
        </div>
        <Word className={classes.word} />
        <div className={classes.right}>
          <div className={statusOnline ? classes.onlineDot : classes.offlineDot} />
        </div>
      </div>
      {pinnedPage && (
        <div className={classes.pinnedPageContainer}>
          <img className={classes.pinnedAvatar} src={pinnedPage.avatarUrls.sm} alt='page img' />
          <Typography variant='h6'>{pinnedPage.name}</Typography>
          <Button
            className={classes.pageButton}
            color='primary'
            onClick={() => setFollowersOpen(true)}
          >
            Followers
          </Button>
          <Button
            className={classes.pageButton}
            color='primary'
            onClick={() => window.open(process.env.REACT_APP_PROFILE_LINK + pinnedPage.username)}
          >
            Page
          </Button>
          <IconButton
            className={classes.closeButton}
            onClick={() => dispatch(pinPage(null))}
          >
            <Close />
          </IconButton>
          <FollowersDialog
            open={followersOpen}
            handleClose={() => setFollowersOpen(false)}
            organisation={pinnedPage}
          />
        </div>
      )}
    </Paper>
  )
}

export default Header
