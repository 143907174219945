import { TablePagination } from '@material-ui/core'
import React, { useEffect } from 'react'

const useKeysetPagination = (fetchData, getLastValue, intervalTime = null) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [data, setData] = React.useState([])
  const [hasMore, setHasMore] = React.useState(true)

  const fetchInitData = async () => {
    const result = await fetchData(rowsPerPage, null)
    setHasMore(result.hasMore)
    setData(result.data)
  }

  const reset = () => {
    setPage(0)
    setData([])
    setHasMore(true)
    fetchInitData()
  }

  useEffect(() => {
    reset()
  }, [fetchData])

  useEffect(() => {
    if (intervalTime != null && intervalTime > 0) {
      const timeout = setTimeout(() => {
        fetchInitData()
      }, intervalTime)
      return () => clearTimeout(timeout)
    }
  }, [data])

  const handleChangePage = async (event, newPage) => {
    if ((newPage + 1) * rowsPerPage > data.length && hasMore) {
      const lastValue = getLastValue(data[data.length - 1])
      const result = await fetchData(rowsPerPage, lastValue)
      setData(data.concat(result.data))
      setHasMore(result.hasMore)
    }
    setPage(newPage)
  }

  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    const result = await fetchData(newRowsPerPage, null)
    setHasMore(result.hasMore)
    setData(result.data)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  const tablePaginationEl = (
    <TablePagination
      nextIconButtonProps={{
        disabled: !hasMore && ((page + 1) * rowsPerPage >= data.length)
      }}
      rowsPerPageOptions={[10, 25, 50, 100]}
      component='div'
      count={data.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
  const startIndex = page * rowsPerPage

  return { data: data.slice(startIndex, startIndex + rowsPerPage), tablePaginationEl, reset }
}

export default useKeysetPagination
