import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  CircularProgress, IconButton,
  TextField,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import UserFeed from '../components/Feed/UserFeed'
import { useParams } from 'react-router'
import apiClient from '../api/ApiClient'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4)
  },
  usersLabel: {
    marginBottom: theme.spacing(2)
  },
  switchContainer: {
    display: 'flex'
  },
  closeButton: {
    cursor: 'pointer',
    margin: theme.spacing(0, 2, 2, 2)
  },
  scoreReport: {
    maxHeight: 400,
    overflow: 'auto',
    padding: theme.spacing(2),
    background: 'white',
    border: '1px solid grey',
    width: 650
  },
  downloadJsonButton: {
    marginTop: theme.spacing(3)
  }
}))

const Feeds = () => {
  const classes = useStyles()
  const [userIds, setUserIds] = useState()
  const [scoresLoading, setScoresLoading] = useState(false)
  const [findId, setFindId] = useState()
  const [show, setShow] = useState(false)
  const { userId: queryId } = useParams()

  const [userId, setUserId] = useState('')
  const [eventId, setEventId] = useState('')
  const [userIdRange, setUserIdRange] = useState('')
  const [scoreReport, setScoreReport] = useState('')
  const [scoreReportLoading, setScoreReportLoading] = useState(false)

  useEffect(() => {
    if (queryId) {
      setFindId(queryId)
      setShow(true)
    }
  }, [queryId])

  const findFeed = () => {
    setShow(true)
  }

  const recalculateScores = async () => {
    setScoresLoading(true)
    const ids = userIds.split(',').map(id => parseInt(id)).filter(Boolean)
    if (ids.length > 0) {
      try {
        await apiClient.admin.recalculateScores(ids)
      } catch (e) {
        console.error(e)
      }
    }
    setScoresLoading(false)
    setUserIds('')
  }

  const getScoreReport = async () => {
    setScoreReportLoading(true)
    try {
      const [fromUserId, untilUserId] = userIdRange.split('-').map(id => parseInt(id.trim()))
      const parsedEventId = parseInt(eventId)

      const ids = [fromUserId, untilUserId, parsedEventId]
      if (ids.some(isNaN)) {
        throw new Error('Invalid input')
      }
      const report = await apiClient.admin.getEventScoreReport(parsedEventId, fromUserId, untilUserId)
      setScoreReport(report)
    } catch (e) {
      console.error(e)
    }
    setScoreReportLoading(false)
  }

  const getUserScoreReport = async () => {
    setScoreReportLoading(true)
    try {
      const parsedUserId = parseInt(userId)

      if (isNaN(parsedUserId)) {
        throw new Error('Invalid input')
      }
      const report = await apiClient.admin.getUserScoreReport(parsedUserId)
      setScoreReport(report)
    } catch (e) {
      console.error(e)
    }
    setScoreReportLoading(false)
  }

  const downloadJson = () => {
    var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(scoreReport))
    var dlAnchorElem = document.getElementById('downloadAnchorElem')
    dlAnchorElem.setAttribute('href', dataStr)
    dlAnchorElem.setAttribute('download', `score-report-${eventId}.json`)
    dlAnchorElem.click()
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant='subtitle1' className={classes.usersLabel}>
          Recalculate scores of users (comma separated)
        </Typography>
        <div className={classes.switchContainer}>
          <TextField
            className={classes.textInput}
            onChange={e => setUserIds(e.target.value)}
            value={userIds}
            label='User IDs'
            variant='outlined'
            type='text'
          />
          <Button onClick={recalculateScores} disabled={scoresLoading}>RECALCULATE</Button>
        </div>
      </div>
      {!show && (
        <div className={classes.container}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Show a users feed
          </Typography>
          <div className={classes.switchContainer}>
            <TextField
              className={classes.textInput}
              onChange={e => setFindId(e.target.value)}
              value={findId}
              label='User ID'
              variant='outlined'
              type='number'
            />
            <Button onClick={findFeed}>SHOW</Button>
          </div>
        </div>
      )}
      {show && (
        <div>
          <IconButton
            aria-label='remove feed'
            edge='start'
            onClick={() => setShow(false)}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>

          <UserFeed userId={findId} />
        </div>
      )}
      <div className={classes.container}>
        <Typography variant='subtitle1' className={classes.usersLabel}>
          Get event score report
        </Typography>
        <div className={classes.switchContainer}>
          <TextField
            className={classes.textInput}
            onChange={e => setEventId(e.target.value)}
            value={eventId}
            label='Event ID'
            variant='outlined'
            type='text'
          />
          <TextField
            className={classes.textInput}
            onChange={e => setUserIdRange(e.target.value)}
            value={userIdRange}
            label='User ID Range (from-to)'
            variant='outlined'
            type='text'
          />
          <Button onClick={getScoreReport} disabled={scoreReportLoading}>GET</Button>
        </div>
        <div className={classes.container}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Get user score report
          </Typography>
          <div className={classes.switchContainer}>
            <TextField
              className={classes.textInput}
              onChange={e => setUserId(e.target.value)}
              value={userId}
              label='User ID'
              variant='outlined'
              type='text'
            />
            <Button onClick={getUserScoreReport} disabled={scoreReportLoading}>GET</Button>
          </div>
        </div>
        {scoreReportLoading && <CircularProgress />}
        {scoreReport && (
          <>
            <Button
              variant='outlined'
              color='primary'
              className={classes.downloadJsonButton}
              onClick={downloadJson}
            >Download
            </Button>
            <a id='downloadAnchorElem' style={{ display: 'none' }} />
            <pre className={classes.scoreReport}>
              {JSON.stringify(scoreReport, null, 2)}
            </pre>
          </>
        )}
      </div>
    </div>
  )
}

export default Feeds
