import { FETCH_ME, RECEIVE_ME } from '../actions/types'
import typeToReducer from 'type-to-reducer'

const initialState = {
  value: undefined,
  loading: true
}

export default typeToReducer({
  [RECEIVE_ME]: (state, { user }) => ({
    ...state,
    value: {
      ...state.value,
      ...user
    },
    loading: false
  }),
  [FETCH_ME]: state => ({
    ...state,
    loading: true
  })
}, initialState)
