export const getEventTypeName = (type, privacyType) => {
  switch (privacyType) {
    case 'PRIVATE':
      switch (type) {
        case 'PINNING':
          return 'Date poll'
        default:
          return 'Party'
      }
    case 'FRIENDS':
      return 'Friends'
    case 'SELECTED':
      return 'Selected friends'
    case 'REQUEST':
      return 'Request'
    case 'PUBLIC':
      return 'Public'
  }
}

export const getEventStateName = state => {
  switch (state) {
    case 'NEW':
      return 'Draft'
    case 'ACTIVE':
      return 'Active'
    case 'CANCELED':
      return 'Canceled'
  }
}

export const makeCsvFile = (rows) => {
  // const csvContent = 'data:text/csv;charset=utf-8,' +

  const csv = rows.map(e => e.join(',')).join('\n')
  // var encodedUri = encodeURI(csvContent)
  // window.open(encodedUri)
  downloadBlob(csv, 'exported-org-contacts.csv', 'text/csv;charset=utf-8;')
}

function downloadBlob (content, filename, contentType) {
  // Create a blob
  var blob = new Blob([content], { type: contentType })
  var url = URL.createObjectURL(blob)

  // Create a link to download it
  var pom = document.createElement('a')
  pom.href = url
  pom.setAttribute('download', filename)
  pom.click()
}
