import { Button, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import LocationAutoComplete from './LocationAutoComplete'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

const styles = theme => ({
  root: {

  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  },
  backButton: {
    backgroundColor: theme.palette.grey.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    marginRight: theme.spacing(2)
  },
  marginTopHalf: {
    marginTop: theme.spacing(0.5)
  },
  map: {
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1)
  }
})

const containerStyle = {
  width: '800px',
  height: '500px',
  maxHeight: '50vh'
}

const center = {
  lat: 52.366800,
  lng: 4.883950
}

const libraries = ['places']

const LocationScreen = ({ classes, onChange, editingEvent }) => {
  const geoCoder = { current: null }

  const [map, setMap] = useState(null)

  const onLoad = React.useCallback(function callback (map) {
    setMap(map)
  }, [])

  const onMapClick = (e) => {
    // console.log(e)
    onChange('location', '')
    onChange('coordinates', { type: 'POINT', coordinates: [e.latLng.lng(), e.latLng.lat()] })
    onChange('address', '')
  }

  useEffect(() => {
    if (!window || !editingEvent.coordinates) return

    if (!geoCoder.current && window.google) {
      geoCoder.current = new window.google.maps.Geocoder()
    }

    map && map.panTo({ lat: editingEvent.coordinates.coordinates[1], lng: editingEvent.coordinates.coordinates[0] })
    map && map.getZoom() < 10 && map.setZoom(10)

    if (geoCoder.current && !editingEvent.location) {
      geoCoder.current.geocode({ location: { lat: editingEvent.coordinates.coordinates[1], lng: editingEvent.coordinates.coordinates[0] } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            onChange('location', results[0].formatted_address)
            onChange('address', '')
          }
        } else {
          onChange('location', editingEvent.coordinates.coordinates.toString())
          onChange('address', '')
        }
      })
    }
  }, [editingEvent.coordinates])

  return (
    <div className={classes.root}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY} libraries={libraries}>
        <LocationAutoComplete map={map} autoFocus inputValue={editingEvent.location} placeholder={editingEvent.location} onChange={onChange} />

        <GoogleMap
          mapContainerClassName={classes.map}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onLoad={onLoad}
          onClick={onMapClick}
          clickableIcons={false}
          options={{
            streetViewControl: false
          }}
        >
          {editingEvent.coordinates && (
            <Marker
              icon={{
                url: '/images/marker.svg',
                scaledSize: window && window.google && new window.google.maps.Size(48, 48)
              }}
              clickable={false}
              position={{ lat: editingEvent.coordinates.coordinates[1], lng: editingEvent.coordinates.coordinates[0] }}
            />
          )}
        </GoogleMap>
      </LoadScript>

      <div className={classes.buttons}>
        <Button className={classes.backButton} onClick={() => onChange('location', null)}>CANCEL</Button>
      </div>
    </div>
  )
}

export default (withStyles(styles)(LocationScreen))
