import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, CircularProgress, Divider, FormControlLabel, Radio, RadioGroup, TextField, Typography, withStyles } from '@material-ui/core'
import apiClient from '../api/ApiClient'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { DateTimePicker, limitFileSize } from '../components/FeaturedItem'
import { DatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import moment from 'moment'
import Switch from '@material-ui/core/Switch'

const styles = theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  addButton: {
    margin: theme.spacing(3, 0)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 900
  },
  buttonsContainer: {
    display: 'flex',
    marginLeft: 'auto'
  },
  usersLabel: {
    marginBottom: theme.spacing(2)
  },
  switchContainer: {
    display: 'flex'
  },
  orgContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  },
  image: {
    width: 256,
    height: 256,
    objectFit: 'cover'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

const stateOptions = [
  'NEW',
  'ACTIVE',
  'UNCLAIMED'
]

const CrawledOrg = ({ classes }) => {
  const [findName, setFindName] = useState('')
  const [loading, setLoading] = useState(false)
  const [org, setOrg] = useState(null)
  const [orgList, setOrgList] = useState(null)
  const [currentOrg, setCurrentOrg] = useState(0)

  const [orgUsername, setOrgUsername] = useState('')
  const [orgName, setOrgName] = useState('')
  const [state, setState] = useState(null)
  const [verified, setVerified] = useState(false)
  const [plan, setPlan] = useState('FREE')
  const [defaultScoreBoost, setDefaultScoreBoost] = useState(false)
  const [orgFees, setOrgFees] = useState([])
  const [avgTicketPrice, setAvgTicketPrice] = useState([])
  const [events, setEvents] = useState({ data: [] })
  const [imgFile, setImgFile] = useState(null)
  const [imgFormat, setImgFormat] = useState(null)

  const findOrg = async () => {
    setLoading(true)
    setCurrentOrg(0)
    const orgList = await apiClient.organisations.findByUsernameOrName(null, findName)
    setOrgList(orgList)
    const org = orgList[0]
    const orgFees = await apiClient.organisations.getFees(org.id)
    setOrgFees(orgFees)
    const avgTicketPrice = await apiClient.organisations.getAvgTicketPrice(org.id)
    setAvgTicketPrice(avgTicketPrice)
    const events = await apiClient.events.getOrg(org.id, 1000)
    setEvents(events)
    setOrg(org)
    setOrgUsername(org.username)
    setOrgName(org.name)
    setPlan(org.plan)
    setDefaultScoreBoost(org.defaultScoreBoost)
    setState(org.state)
    setVerified(org.verified)
    setLoading(false)
  }

  const patchOrg = async () => {
    setLoading(true)
    const patchObj = {
      username: null,
      name: null,
      state: null,
      verified: null
    }
    if (org.username !== orgUsername) patchObj.username = orgUsername
    if (org.name !== orgName) patchObj.name = orgName
    if (org.state !== state) patchObj.state = state
    if (org.verified !== verified) patchObj.verified = verified
    if (org.plan !== plan) patchObj.plan = plan
    if (org.defaultScoreBoost !== defaultScoreBoost) patchObj.defaultScoreBoost = defaultScoreBoost
    const newOrg = await apiClient.organisations.patch(org.id, patchObj)
    const orgFees = await apiClient.organisations.getFees(newOrg.id)
    setOrgFees(orgFees)
    const avgTicketPrice = await apiClient.organisations.getAvgTicketPrice(newOrg.id)
    setAvgTicketPrice(avgTicketPrice)
    const events = await apiClient.events.getOrg(org.id, 1000)
    setEvents(events)
    setOrg(newOrg)
    setOrgUsername(newOrg.username)
    setOrgName(newOrg.name)
    setState(newOrg.sate)
    setVerified(newOrg.verifed)
    setPlan(newOrg.plan)
    setDefaultScoreBoost(newOrg.defaultScoreBoost)
    const uploadUrl = await apiClient.organisations.getAvatarUploadUrl(org.username, imgFormat)
    await uploadImage(uploadUrl, imgFile)
    setLoading(false)
  }

  const addOrgFee = async () => {
    const date = new Date()
    const newFee = {
      orgFees: [
        {
          fee: 0.00,
          startDate: date.toISOString(),
          endDate: date.toISOString()
        }
      ]
    }
    await apiClient.organisations.addFee(org.id, newFee)
    const orgFees = await apiClient.organisations.getFees(org.id)
    setOrgFees(orgFees)
  }

  const patchOrgFee = async (index) => {
    const fee = orgFees[index]
    await apiClient.organisations.patchFee(org.id, fee.id, fee)
  }

  const deleteFee = async (index) => {
    const fee = orgFees[index]
    await apiClient.organisations.deleteFee(org.id, fee.id)
    const newOrgFees = await apiClient.organisations.getFees(org.id)
    setOrgFees(newOrgFees)
  }

  const addOrgAvgPrice = async () => {
    const date = new Date()
    const newAvgPrice = {
      avgPrices: [
        {
          price: 0.00,
          startDate: date.toISOString(),
          endDate: date.toISOString()
        }
      ]
    }
    await apiClient.organisations.addAvgTicketPrice(org.id, newAvgPrice)
    const orgAvgPrices = await apiClient.organisations.getAvgTicketPrice(org.id)
    setAvgTicketPrice(orgAvgPrices)
  }

  const patchOrgAvgPrice = async (index) => {
    const avgPrice = avgTicketPrice[index]
    await apiClient.organisations.patchAvgTicketPrice(org.id, avgPrice.id, avgPrice)
  }

  const deleteAvgPrice = async (index) => {
    const avgPrice = avgTicketPrice[index]
    await apiClient.organisations.deleteAvgTicketPrice(org.id, avgPrice.id)
    const newOrgAvgPrices = await apiClient.organisations.getAvgTicketPrice(org.id)
    setAvgTicketPrice(newOrgAvgPrices)
  }

  useEffect(() => {
    if (imgFile != null) {
      setImgFormat(imgFile.type)
    }
  }, [imgFile])

  const uploadImage = async (uploadUrl, imgFile) => {
    if (!imgFile) {
      window.alert('tried to upload image without an image selected')
      return
    }
    const limitedImg = await limitFileSize(imgFile)
    await fetch(uploadUrl, {
      method: 'PUT',
      header: {
        'Content-Type': imgFile.type
      },
      body: limitedImg
    })
  }

  const nextOrg = async () => {
    setLoading(true)
    setCurrentOrg(currentOrg + 1)
    if (orgList && orgList.length >= currentOrg + 1) {
      const org = orgList[currentOrg]
      const orgFees = await apiClient.organisations.getFees(org.id)
      setOrgFees(orgFees)
      const avgTicketPrice = await apiClient.organisations.getAvgTicketPrice(org.id)
      setAvgTicketPrice(avgTicketPrice)
      const events = await apiClient.events.getOrg(org.id, 1000)
      setEvents(events)
      setOrg(orgList[currentOrg])
      setOrgUsername(orgList[currentOrg].username)
      setOrgName(orgList[currentOrg].name)
      setState(orgList[currentOrg].state)
      setVerified(orgList[currentOrg].verified)
      setDefaultScoreBoost(orgList[currentOrg].defaultScoreBoost)
      setPlan(orgList[currentOrg].plan)
    }
    setLoading(false)
  }

  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item xs={12}>
        <div className={classes.container}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Org management
          </Typography>
          <div className={classes.switchContainer}>
            <TextField
              className={classes.textInput}
              onChange={e => setFindName(e.target.value)}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  findOrg()
                }
              }}
              value={findName}
              label='Org name'
              variant='outlined'
            />
            <Button onClick={findOrg}>FIND</Button>
            {orgList && orgList.length > 1 &&
              <Button onClick={nextOrg}>NEXT</Button>}
          </div>
          {loading && <CircularProgress />}
          {org &&
            <div className={classes.orgContainer}>
              <Typography>Organisation found!</Typography>
              {org.state !== 'UNCLAIMED' &&
                <Typography variant='h4' style={{ color: 'red' }}>
                  THIS ORGANISATION IS ALREADY CLAIMED! BE CAREFUL WHEN EDITING
                </Typography>}
              <TextField
                className={classes.textInput}
                onChange={e => setOrgUsername(e.target.value)}
                value={orgUsername}
                label='org username'
                variant='outlined'
              />
              <TextField
                className={classes.textInput}
                onChange={e => setOrgName(e.target.value)}
                value={orgName}
                label='org name'
                variant='outlined'
              />
              <FormControl variant='outlined' className={classes.formControl}>
                <Select
                  value={state}
                  onChange={e => setState(e.target.value)}
                >
                  {stateOptions.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                </Select>
              </FormControl>
              <Divider />
              <Typography>Organisation ID: {org.id}</Typography>
              <Divider />
              <Typography>Organisation code: {org.code}</Typography>
              <Divider />
              <div>
                <img src={imgFile ? URL.createObjectURL(imgFile) : org?.avatarUrls?.xl} className={classes.image} />
                <input type='file' onChange={e => setImgFile(e.target.files[0])} />
              </div>
              <Divider />
              <Typography>Verified:</Typography>
              <RadioGroup
                name='Verified'
                value={String(verified)}
                onChange={e => {
                  const value = e.target.value === 'true'
                  setVerified(value)
                }}
              >
                <FormControlLabel
                  value='true'
                  control={<Radio />}
                  label='true'
                />
                <FormControlLabel
                  value='false'
                  control={<Radio />}
                  label='false'
                />
              </RadioGroup>
              <Divider />
              <Typography>Plan:</Typography>
              <RadioGroup
                name='Plan'
                value={plan}
                onChange={e => {
                  setPlan(e.target.value)
                }}
              >
                <FormControlLabel
                  value='PRO'
                  control={<Radio />}
                  label='PRO'
                />
                <FormControlLabel
                  value='FREE'
                  control={<Radio />}
                  label='FREE'
                />
              </RadioGroup>
              <Divider />
              <Typography>Boost score by default:</Typography>
              <RadioGroup
                name='Default score boost'
                value={String(defaultScoreBoost)}
                onChange={e => {
                  const value = e.target.value === 'true'
                  setDefaultScoreBoost(value)
                }}
              >
                <FormControlLabel
                  value='true'
                  control={<Radio />}
                  label='true'
                />
                <FormControlLabel
                  value='false'
                  control={<Radio />}
                  label='false'
                />
              </RadioGroup>
              <Divider />
              <Button onClick={patchOrg}>Save everything above</Button>
              <Divider />
              <Typography>Org fees:</Typography>
              {orgFees.map((fee, index) => {
                return (
                  <div className={classes.row} key={fee.id}>
                    <Typography>Id: {fee.id}</Typography>
                    <DateTimePicker
                      label='Start date' date={orgFees[index].startDate} setDate={(date) => {
                        setOrgFees(orgFees.map((of, idx) => {
                          if (idx === index) {
                            return {
                              ...of,
                              startDate: date.toISOString()
                            }
                          }
                          return of
                        }))
                      }}
                    />
                    <DateTimePicker
                      label='End date' date={orgFees[index].endDate} setDate={(date) => {
                        setOrgFees(orgFees.map((of, idx) => {
                          if (idx === index) {
                            return {
                              ...of,
                              endDate: date.toISOString()
                            }
                          }
                          return of
                        }))
                      }}
                    />
                    <TextField
                      value={orgFees[index].fee}
                      onChange={(e) => {
                        setOrgFees(orgFees.map((of, idx) => {
                          if (idx === index) {
                            return {
                              ...of,
                              fee: e.target.value
                            }
                          }
                          return of
                        }))
                      }}
                      label='Fee'
                      type='number'
                    />
                    <Button onClick={() => deleteFee(index)}>DELETE</Button>
                    <Button onClick={() => patchOrgFee(index)}>SAVE</Button>
                  </div>
                )
              })}
              <Button onClick={addOrgFee}>Add new</Button>
              <Divider />
              <Typography>Avg price:</Typography>
              {avgTicketPrice.map((price, index) => {
                return (
                  <div className={classes.row} key={price.id}>
                    <Typography>Id: {price.id}</Typography>
                    <DateTimePicker
                      label='Start date' date={avgTicketPrice[index].startDate} setDate={(date) => {
                        setAvgTicketPrice(avgTicketPrice.map((of, idx) => {
                          if (idx === index) {
                            return {
                              ...of,
                              startDate: date.toISOString()
                            }
                          }
                          return of
                        }))
                      }}
                    />
                    <DateTimePicker
                      label='End date' date={avgTicketPrice[index].endDate} setDate={(date) => {
                        setAvgTicketPrice(avgTicketPrice.map((of, idx) => {
                          if (idx === index) {
                            return {
                              ...of,
                              endDate: date.toISOString()
                            }
                          }
                          return of
                        }))
                      }}
                    />
                    <TextField
                      value={avgTicketPrice[index].price}
                      onChange={(e) => {
                        setAvgTicketPrice(avgTicketPrice.map((of, idx) => {
                          if (idx === index) {
                            return {
                              ...of,
                              price: e.target.value
                            }
                          }
                          return of
                        }))
                      }}
                      label='Price'
                      type='number'
                    />
                    <Button onClick={() => deleteAvgPrice(index)}>DELETE</Button>
                    <Button onClick={() => patchOrgAvgPrice(index)}>SAVE</Button>
                  </div>
                )
              })}
              <Button onClick={addOrgAvgPrice}>Add new</Button>
              <Divider />
              <Typography>Events:</Typography>
              {events.data.map((event, index) => {
                return (
                  <div className={classes.row} key={event.id}>
                    <Typography>Id: {event.id}</Typography>
                    <Typography>{event.name}</Typography>
                    <Typography>{dayjs(event.startDate).format('DD/MM/YYYY - HH:mm')}</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={event.scoreBoost} onChange={async (e) => {
                            await apiClient.events.setScoreBoost(event.id, { value: e.target.checked })
                            const events = await apiClient.events.getOrg(org.id, 1000)
                            setEvents(events)
                          }} name='Boost scores'
                        />
                      }
                      label='Boost scores'
                    />

                  </div>
                )
              })}
              <Divider />
            </div>}
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(CrawledOrg)
