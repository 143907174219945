import React, { useState } from 'react'
import { Button, CircularProgress, withStyles } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import InfiniteScroll from 'react-infinite-scroller'
import apiClient from '../../api/ApiClient'
import { history } from '../../config/store'
import { useDispatch, useSelector } from 'react-redux'
import { closeUserFollowing, pinPage } from '../../actions'

const styles = theme => ({
  root: {
    height: '700px',
    width: '600px',
    maxWidth: 'unset'
  },
  followersList: {
    overflow: 'auto'
  },
  profile: {
    padding: theme.spacing(1, 1, 1, 0),
    display: 'flex',
    alignItems: 'center'
  },
  profileAvatar: {
    width: 32,
    height: 32,
    borderRadius: '100px',
    marginRight: theme.spacing(2)
  },
  feedButton: {
    marginLeft: 'auto'
  },
  notifsButton: {
    marginLeft: theme.spacing(1)
  }
})

const FOLLOWING_PAGE_SIZE = 10

function FollowingDialog ({ classes }) {
  const dispatch = useDispatch()
  const open = useSelector(state => state.ui.followingDialog.open)
  const userId = useSelector(state => state.ui.followingDialog.userId)
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const ref = React.useRef()

  React.useEffect(() => {
    if (!open) {
      setItems([])
      setHasMore(true)
    }
  }, [open])

  const loadMore = async () => {
    if (userId) {
      setLoading(true)
      const page = Math.floor(items.length / FOLLOWING_PAGE_SIZE)
      // dispatch(fetchActivity(page, FOLLOWER_PAGE_SIZE))
      const newItems = await apiClient.admin.getUserFollowing(userId, page, FOLLOWING_PAGE_SIZE)
      setHasMore(newItems.length === FOLLOWING_PAGE_SIZE)
      setItems(prev => [...prev, ...newItems])
      // eslint-disable-next-line promise/param-names
      setLoading(false)
    }
  }

  const handleClose = () => {
    dispatch(closeUserFollowing())
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      classes={{
        paper: classes.root
      }}
    >
      <DialogTitle id='alert-dialog-title'>Following</DialogTitle>
      <DialogContent ref={ref}>
        <div className={classes.followersList}>
          <InfiniteScroll
            pageStart={-1}
            loadMore={loadMore}
            hasMore={((items.length / FOLLOWING_PAGE_SIZE) % 1 === 0 && !loading && hasMore)}
            useWindow={false}
            threshold={10}
            getScrollParent={() => ref.current}
          >
            {items.map((item, index) => {
              return (
                <div
                  className={classes.profile}
                  key={index}
                >
                  <img src={item.avatarUrls?.sm} className={classes.profileAvatar} alt='profile avatar' />
                  {item.name + ` (${item.id})`}
                  <Button
                    className={classes.feedButton} color='primary'
                    onClick={() => window.open(process.env.REACT_APP_PROFILE_LINK + item.username)}
                  >
                    Page
                  </Button>
                  <Button
                    color='primary'
                    onClick={() => {
                      dispatch(pinPage(item))
                      handleClose()
                    }}
                  >
                    Pin
                  </Button>
                </div>
              )
            })}
            {loading && <CircularProgress />}
          </InfiniteScroll>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(FollowingDialog)
