import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Paper, Switch, TableBody, Typography, withStyles } from '@material-ui/core'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { patchOrganisation, pinPage } from '../actions'
import _ from 'underscore'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import FollowingIcon from '../media/list-check.svg'
import TooltipAccountIcon from '../media/tooltip-account.svg'
import CalendarEditIcon from '../media/calendar-edit.svg'
import MoreIcon from '@material-ui/icons/MoreVert'
import Tooltip from '@material-ui/core/Tooltip'
import classNames from 'classnames'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import useKeysetPagination from '../hooks/useKeysetPagination'
import apiClient from '../api/ApiClient'
import FollowersDialog from '../components/FollowersDialog'
import UserProfile from '../components/UserProfile/UserProfile'
import { makeCsvFile } from '../util'

const styles = theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  usersItem: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: 56,
    overflowX: 'auto'
  },
  usersLabel: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit
  },
  tableCellRoot: {
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    '& img': {
      verticalAlign: 'middle'
    }
  },
  cellIcon: {
    verticalAlign: 'bottom',
    marginRight: theme.spacing.unit * 2
  },
  avatar: {
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
    borderRadius: 1000,
    verticalAlign: 'bottom',
    marginRight: theme.spacing.unit * 2
  },
  clickableCell: {
    cursor: 'pointer'
  },
  adminsDialog: {
    minWidth: 800
  }
})

const Users = ({ classes, patchOrganisation }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const dispatch = useDispatch()
  const [selectedOrganisation, setSelectedOrganisation] = React.useState(null)
  const [admins, setAdmins] = React.useState(null)
  const [currentFollowers, setCurrentFollowers] = React.useState(null)
  const [managedByUsers, setManagedByUsers] = React.useState(false)
  const open = Boolean(anchorEl)

  console.log('managedByUsers', managedByUsers)

  const fetchOrganisations = useCallback((limit, lastValue) =>
    apiClient.organisations.get(limit, lastValue, 'CREATED_AT', managedByUsers),
  [managedByUsers])

  const { data: organisations, tablePaginationEl, reset } = useKeysetPagination(
    fetchOrganisations,
    org => org.createdAt
  )

  const openMenu = (event, organisation) => {
    setAnchorEl(event.currentTarget)
    setSelectedOrganisation(organisation)
  }

  const handleManagedByUsers = (event) => {
    console.log(event.target.checked)
    setManagedByUsers(event.target.checked)
    // setTimeout(reset, 200)
  }

  const approveOrganisation = () => {
    patchOrganisation(selectedOrganisation.id, { ...selectedOrganisation, state: 'ACTIVE' })
    onMenuClose()
  }

  const disApproveOrganisation = () => {
    patchOrganisation(selectedOrganisation.id, { ...selectedOrganisation, state: 'PENDING_APPROVAL' })
    onMenuClose()
  }

  const setPinnedPage = () => {
    dispatch(pinPage(selectedOrganisation))
    onMenuClose()
  }

  const onMenuClose = () => {
    setAnchorEl(null)
    setSelectedOrganisation(null)
  }

  const onAdminsDialogOpen = () => {
    setAdmins(selectedOrganisation.admins ?? [])
    onMenuClose()
  }

  const exportToCsv = () => {
    const rows = organisations.flatMap(organisation =>
      organisation.admins?.map(admin => ([organisation.name, organisation.id, admin.role, admin.user.id, admin.user.name, admin.user.emailAddress, admin.user.phoneNumber])) ?? []
    )
    rows.unshift(['Organisation Name', 'Organisation ID', 'Role', 'User ID', 'User Name', 'User Email', 'User Phone'])
    makeCsvFile(rows)
  }

  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item xs={12}>
        <Button variant='contained' color='primary' style={{ marginBottom: 24 }} onClick={exportToCsv}>Export Contact Info to CSV</Button>
        <Paper elevation={2} className={classes.usersItem}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Organisations online in the past 7 days ({_.filter(organisations, organisation => organisation.state !== 'NEW').length})
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16, marginLeft: 16 }}>
            <Typography style={{ marginRight: 16 }}>Managed by users: </Typography>
            <Switch checked={managedByUsers} onChange={handleManagedByUsers} />
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.tableCellRoot }}>Name (ID)</TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>Online</TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>Signed up</TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>Activity </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {organisations.map(organisation => {
                return (
                  <TableRow key={organisation.id}>
                    <TableCell
                      classes={{ root: classes.tableCellRoot }} className={classes.clickableCell} onClick={() => {
                        window.open(process.env.REACT_APP_PROFILE_LINK + organisation.username)
                      }}
                    >
                      <img
                        className={classes.avatar}
                        src={organisation.avatarUrls?.sm}
                      />
                      {organisation.name} ({organisation.id})
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {moment(organisation.lastOnline).format('dd HH:mm')}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {moment(organisation.createdAt).format('dd D MMM HH:mm')}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {organisation.eventCount !== 0 &&
                        <Tooltip title='Event count' aria-label='Event count'>
                          <span>
                            <b>{organisation.eventCount}</b>
                            <img src={CalendarEditIcon} className={classNames(classes.cellIcon)} />
                          </span>
                        </Tooltip>}
                      {organisation.followerCount !== 0 &&
                        <Tooltip title='Follower count' aria-label='Follower count'>
                          <span>
                            <b>{organisation.followerCount}</b>
                            <img onClick={() => setCurrentFollowers(organisation)} style={{ cursor: 'pointer' }} src={FollowingIcon} className={classNames(classes.cellIcon)} />
                          </span>
                        </Tooltip>}
                      {organisation.guestCount !== 0 &&
                        <Tooltip title='Guest count' aria-label='Guest count'>
                          <span>
                            <b>{organisation.guestCount}</b>
                            <img src={TooltipAccountIcon} className={classNames(classes.cellIcon)} />
                          </span>
                        </Tooltip>}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {['ACTIVE', 'PENDING_APPROVAL'].includes(organisation.state) &&
                        <IconButton onClick={(event) => openMenu(event, organisation)}>
                          <MoreIcon />
                        </IconButton>}
                    </TableCell>
                  </TableRow>
                )
              }
              )}
            </TableBody>
          </Table>
          {tablePaginationEl}
          <Menu
            id='simple-menu'
            keepMounted
            open={open}
            anchorEl={anchorEl}
            onClose={onMenuClose}
          >
            {selectedOrganisation && selectedOrganisation.state === 'PENDING_APPROVAL' && <MenuItem onClick={approveOrganisation}>Approve</MenuItem>}
            {selectedOrganisation && selectedOrganisation.state === 'ACTIVE' && <MenuItem onClick={disApproveOrganisation}>Disapprove</MenuItem>}
            <MenuItem onClick={setPinnedPage}>Pin page</MenuItem>
            <MenuItem onClick={onAdminsDialogOpen}>Show Admins</MenuItem>
          </Menu>
          <FollowersDialog
            open={Boolean(currentFollowers)}
            handleClose={() => setCurrentFollowers(null)}
            organisation={currentFollowers}
          />
          <Dialog
            PaperProps={{ className: classes.adminsDialog }}
            open={admins != null}
            onClose={() => setAdmins(null)}
          >
            <DialogTitle>Admins</DialogTitle>
            <DialogContent>
              {(admins != null && admins.length > 0) ? admins.map(admin => {
                return (
                  <div key={admin.id}>
                    <UserProfile user={admin.user} />
                  </div>
                )
              }) : 'No admins'}
            </DialogContent>
          </Dialog>
        </Paper>
      </Grid>
    </Grid>
  )
}

function mapStateToProps ({ data }) {
  return {}
}

export default connect(mapStateToProps, { patchOrganisation })(withStyles(styles)(Users))
