import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useTheme } from '@material-ui/core/styles'
import { TOGGLE_DRAWER } from '../actions/types'
import { history } from '../config/store'
import { withStyles } from '@material-ui/core'

const drawerWidth = 128

const styles = theme => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('xl')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('xl')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      display: 'none'
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerPaperMobile: {
    width: drawerWidth
  },
  content: {
  }
})

function NavDrawer ({ classes }) {
  const theme = useTheme()
  const drawerOpen = useSelector(state => state.ui.drawerOpen)
  const dispatch = useDispatch()
  const toggle = () => dispatch({ type: TOGGLE_DRAWER })

  const content = (
    <div className={classes.content}>
      <List>
        {[{
          name: 'Home',
          path: '/'
        }, {
          name: 'Users',
          path: '/users'
        }, {
          name: 'Events',
          path: '/events'
        }, {
          name: 'Feeds',
          path: '/feeds'
        }, {
          name: 'User Notifications',
          path: '/userNotifications'
        }, {
          name: 'Suggestions',
          path: '/suggestions'
        }, {
          name: 'Guests',
          path: '/guests'
        }, {
          name: 'Orgs',
          path: '/onlineOrganisations'
        }, {
          name: 'Settings',
          path: '/settings'
        }, {
          name: 'Notifications',
          path: '/notifications'
        }, {
          name: 'Crawled Events',
          path: '/crawledEvents'
        }, {
          name: 'Org management',
          path: '/crawledOrg'
        }, {
          name: 'Create Event',
          path: '/createEvent'
        }, {
          name: 'Event Management',
          path: '/eventManagement'
        }, {
          name: 'User Management',
          path: '/userManagement'
        }, {
          name: 'Reports',
          path: '/reports'
        }].map(({ name, path }) => (
          <ListItem button key={name} onClick={() => history.push(path)}>
            <ListItemText primary={name} />
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden xlUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={drawerOpen}
            onClose={toggle}
            classes={{
              paper: classes.drawerPaperMobile
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {content}
          </Drawer>
        </Hidden>
        <Hidden lgDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant='permanent'
            open
          >
            {content}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  )
}

export default withStyles(styles)(NavDrawer)
