import React, { useReducer, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { Button, Typography, withStyles, TextField } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import LocationScreen from '../components/LocationScreen'
import { DateTimePicker, limitFileSize } from '../components/FeaturedItem'
import apiClient from '../api/ApiClient'

const styles = theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  addButton: {
    margin: theme.spacing(3, 0)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: theme.spacing(3)
    }
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 1300
  },
  buttonsContainer: {
    display: 'flex',
    marginLeft: 'auto'
  },
  usersLabel: {
    marginBottom: theme.spacing(2)
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  pageButtonsContainer: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  textInput: {
    width: 600
  },
  formControl: {
    width: 200
  },
  image: {
    maxWidht: 360,
    maxHeight: 480
  }
})

const initialState = {
  name: '',
  description: '',
  location: '',
  coordinates: null,
  address: '',
  startDate: new Date(),
  endDate: null,
  ticketLink: '',
  imageFormat: 'image/jpeg',
  organisation: {
    username: '',
    name: ''
  },
  extraAttendees: 0,
  extraLikes: 0,
  category: 'OTHER'
}

function reducer (state, action) {
  const { key, value } = action.payload
  switch (action.type) {
    case 'change':
      if (key.includes('.')) {
        const keys = key.split('.')
        return {
          ...state,
          [keys[0]]: {
            ...state[keys[0]],
            [keys[1]]: value
          }
        }
      } else {
        return {
          ...state,
          [key]: value
        }
      }
    default:
      throw new Error()
  }
}

const CreateEvent = ({ classes }) => {
  const [loading, setLoading] = useState(false)
  const [event, dispatch] = useReducer(reducer, initialState)
  const [imgFileEvent, setImgFileEvent] = useState(null)
  const [imgFileOrganisation, setImgFileOrganisation] = useState(null)
  const categories = useSelector(state => state.data.categories)

  const onChange = (key, value) => {
    dispatch({ type: 'change', payload: { key, value } })
  }

  const saveEvent = async () => {
    setLoading(true)

    if (isNaN(parseInt(event.extraAttendees))) {
      window.alert('extraAttendees needs to be a valid integer')
    }
    if (isNaN(parseInt(event.extraLikes))) {
      window.alert('extraLikes needs to be a valid integer')
    }
    const postEvent = { ...event }
    if (event.address === '') {
      postEvent.address = null
    }
    if (event.location === '') {
      postEvent.location = null
    }
    if (event.ticketLink === '') {
      postEvent.ticketLink = null
    }
    try {
      const response = await apiClient.crawledEvents.create(postEvent)
      await uploadImage(response.eventImagePutUrl, imgFileEvent)
      if (!response.organisationExists && imgFileOrganisation != null) {
        const organisationImagePutUrl = await apiClient.organisations.getAvatarUploadUrl(event.organisation.username, imgFileOrganisation.type)
        await uploadImage(organisationImagePutUrl, imgFileOrganisation)
      }
    } catch (e) {
      window.alert(`something went wrong: ${e}`)
    }
    setLoading(false)
  }

  const selectImage = async (event, setImgFile) => {
    const file = event.target.files[0]
    setImgFile(file)
  }

  useEffect(() => {
    if (imgFileEvent != null) {
      onChange('imageFormat', imgFileEvent.type)
    }
  }, [imgFileEvent])

  const uploadImage = async (uploadUrl, imgFile) => {
    if (!imgFile) {
      window.alert('tried to upload image without an image selected')
      return
    }
    const limitedImg = await limitFileSize(imgFile)
    await fetch(uploadUrl, {
      method: 'PUT',
      header: {
        'Content-Type': imgFile.type
      },
      body: limitedImg
    })
  }

  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item xs={12}>
        <div className={classes.container}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Create event
          </Typography>
          <TextField
            className={classes.textInput}
            onChange={e => onChange('name', e.target.value)}
            value={event.name}
            label='name'
            variant='outlined'
          />
          <div>
            <img src={imgFileEvent ? URL.createObjectURL(imgFileEvent) : ''} className={classes.image} />
            <input type='file' onChange={e => selectImage(e, setImgFileEvent)} />
          </div>
          <TextField
            classes={{
              input: classes.description
            }}
            minRows={4}
            maxRows={12}
            className={classes.textInput}
            onChange={e => onChange('description', e.target.value)}
            value={event.description}
            label='description'
            variant='outlined'
            multiline
          />
          <DateTimePicker date={event.startDate} setDate={d => onChange('startDate', d)} title='Start date' />
          {event.endDate == null ?
            <Button onClick={() => onChange('endDate', new Date())}>Add end date</Button> :
            <div>
              <DateTimePicker date={event.endDate} setDate={d => onChange('endDate', d)} title='End date' />
              <Button onClick={() => onChange('endDate', null)}>Remove end date</Button>
            </div>}
          <LocationScreen onChange={onChange} editingEvent={event} />
          <TextField
            className={classes.textInput}
            type='number'
            InputProps={{ inputProps: { min: 0, max: 100000000 } }}
            onChange={e => onChange('extraAttendees', e.target.value)}
            value={event.extraAttendees}
            label='extra attendees'
            variant='outlined'
          />
          <TextField
            className={classes.textInput}
            type='number'
            InputProps={{ inputProps: { min: 0, max: 100000000 } }}
            onChange={e => onChange('extraLikes', e.target.value)}
            value={event.extraLikes}
            label='extra likes'
            variant='outlined'
          />
          <TextField
            className={classes.textInput}
            onChange={e => onChange('ticketLink', e.target.value)}
            value={event.ticketLink}
            label='ticket link'
            variant='outlined'
          />
          <FormControl variant='outlined' className={classes.formControl}>
            <Select
              value={event.categoryId}
              onChange={e => onChange('categoryId', e.target.value)}
            >
              {categories.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)}
            </Select>
          </FormControl>

          <Typography variant='h4'>Organisation</Typography>
          <TextField
            className={classes.textInput}
            onChange={e => onChange('organisation.username', e.target.value)}
            value={event.organisation.username}
            label='username'
            variant='outlined'
          />
          <TextField
            className={classes.textInput}
            onChange={e => onChange('organisation.name', e.target.value)}
            value={event.organisation.name}
            label='name'
            variant='outlined'
          />
          <div>
            <img src={imgFileOrganisation ? URL.createObjectURL(imgFileOrganisation) : ''} className={classes.image} />
            <input type='file' onChange={e => selectImage(e, setImgFileOrganisation)} />
          </div>
          <Button disabled={loading} onClick={saveEvent}>SAVE</Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(CreateEvent)
