import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Typography, withStyles } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import InfiniteScroll from 'react-infinite-scroller'
import apiClient from '../api/ApiClient'
import { history } from '../config/store'
import { useDispatch, useSelector } from 'react-redux'
import { closeUserProfile, openUserFollowing } from '../actions'

const styles = theme => ({
  root: {
    width: '400px',
    maxWidth: 'unset',
    display: 'flex',
    flexDirection: 'column'
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 64,
    width: 64,
    borderRadius: 100,
    object: 'cover',
    marginRight: theme.spacing(2)
  },
  countItem: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: 1,
    background: theme.palette.grey.main,
    margin: theme.spacing(2, -3)
  },
  statContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center'
  },
  statRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around'
  },
  buttonsContainer: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

function UserDialog ({ classes }) {
  const [loading, setLoading] = useState(false)
  const open = useSelector(state => state.ui.userDialog.open)
  const userId = useSelector(state => state.ui.userDialog.userId)
  const [user, setUser] = useState({})
  const dispatch = useDispatch()

  const loadUser = async () => {
    if (userId !== null) {
      setLoading(true)
      const newUser = await apiClient.users.getProfile(userId)
      setUser(newUser)
      setLoading(false)
    }
  }

  const handleClose = () => {
    dispatch(closeUserProfile())
  }

  const closeAndPush = (path) => {
    handleClose()
    history.push(path)
  }

  useEffect(() => {
    if (open) {
      loadUser()
    } else {
      setUser({})
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      classes={{
        paper: classes.root
      }}
    >
      <DialogContent>
        <div className={classes.topContainer}>
          <img src={user.avatarUrls?.lg} alt='avatar' className={classes.avatar} />
          <Typography variant='h4'>{user.name}</Typography>
        </div>
        <div className={classes.divider} />
        <div className={classes.statRow}>
          <div
            className={classes.statContainer}
          >
            <Typography variant='h5'><b>{user.friends}</b></Typography>
            <Typography variant='caption' color='textSecondary'>friends</Typography>
          </div>
          <div
            className={classes.statContainer}
          >
            <Typography variant='h5'><b>{user.eventsHosted}</b></Typography>
            <Typography variant='caption' color='textSecondary'>hosted</Typography>
          </div>
          <div
            className={classes.statContainer}
          >
            <Typography variant='h5'><b>{user.eventsAttended}</b></Typography>
            <Typography variant='caption' color='textSecondary'>attended</Typography>
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.buttonsContainer}>
          <Button
            color='primary'
            onClick={() => closeAndPush(`/feeds/${user.id}`)}
          >
            Feed
          </Button>
          <Button
            color='primary'
            onClick={() => closeAndPush(`/userNotifications/${user.id}`)}
          >
            Notifications
          </Button>
          <Button
            color='primary'
            onClick={() => closeAndPush(`/suggestions/${user.id}`)}
          >
            Suggestions
          </Button>
          <Button
            color='primary'
            onClick={() => dispatch(openUserFollowing(userId))}
          >
            Following
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(UserDialog)
