import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import logo from '../media/icon.svg'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  loaderRoot: {
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'absolute',
    flexDirection: 'column',
    top: 0,
    background: theme.palette.background.default,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999
  },
  loader: {
    marginTop: theme.spacing(4)
  }
})

function LoadingScreen ({ classes }) {
  return (
    <div className={classes.loaderRoot}>
      <img src={logo} alt='BASH' height={48} />
      <CircularProgress className={classes.loader} color='primary' />
    </div>
  )
}

export default withStyles(styles)(LoadingScreen)
