import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  CircularProgress, FormGroup, Switch,
  Typography
} from '@material-ui/core'
import apiClient from '../../api/ApiClient'
import InfiniteScroll from 'react-infinite-scroller'
import NotificationItem from './NotificationItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { openUserProfile } from '../../actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '700px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  titleContainer: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  feedContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  scrollContainer: {
    overflow: 'scroll'
  },
  title: {
    marginRight: 'auto'
  }
}))

const USER_NOTIFICATION_PAGE_SIZE = 10

const UserNotificationList = ({ userId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [hideHidden, setHideHidden] = useState(true)

  React.useEffect(() => {
    if (!userId) {
      setItems([])
      setHasMore(true)
    }
  }, [userId])

  const loadMore = async () => {
    if (userId !== null) {
      setLoading(true)
      const page = Math.floor(items.length / USER_NOTIFICATION_PAGE_SIZE)
      // dispatch(fetchActivity(page, FOLLOWER_PAGE_SIZE))
      const newItems = await apiClient.admin.getUserNotifications(userId, page, USER_NOTIFICATION_PAGE_SIZE)
      setHasMore(newItems.length === USER_NOTIFICATION_PAGE_SIZE)
      setItems(prev => [...prev, ...newItems])
      // eslint-disable-next-line promise/param-names
      setLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant='h4'>
          User notifications
        </Typography>
        <Button className={classes.title} color='primary' onClick={() => dispatch(openUserProfile(userId))}>
          Show profile
        </Button>
        <FormControlLabel
          control={<Switch checked={hideHidden} onChange={(e) => setHideHidden(e.target.checked)} />}
          label='Hide hidden'
        />
      </div>
      <div className={classes.scrollContainer}>
        <InfiniteScroll
          pageStart={-1}
          loadMore={loadMore}
          hasMore={((items.length / USER_NOTIFICATION_PAGE_SIZE) % 1 === 0 && !loading && hasMore)}
          threshold={800}
        >
          <div className={classes.feedContainer}>
            {items.map((item, index) => {
              if (item.hidden && hideHidden) return null
              return (
                <div key={index}>
                  <NotificationItem item={item} />
                </div>
              )
            })}
            {loading && <CircularProgress />}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default UserNotificationList
