import React, { useCallback, useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { signIn, verifyEmail } from '../actions'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { ReactComponent as Logo } from '../media/icon.svg'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router'
import FlatButton from '../components/FlatButton'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.container,
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    minWidth: 375,
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1
  },
  logo: {
    marginBottom: theme.spacing(2),
    height: 56,
    width: 56
  },
  paper: {
    width: '100%',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  halfMarginBottom: {
    marginBottom: theme.spacing()
  },
  input: {
    marginTop: theme.spacing(2)
  },
  submit: {
    marginTop: theme.spacing(2)
  },
  loader: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

const SignIn = ({ classes, signIn, verifyEmail, requesting, submitting, inEmail }) => {
  const [email, setEmail] = useState('')
  const { email: emailParam, token: tokenParam } = useParams()
  const [invalidEmail, setInvalidEmail] = useState(false)

  useEffect(() => {
    setEmail(emailParam)
  }, [emailParam])

  useEffect(() => {
    if (emailParam && tokenParam) {
      verifyEmail(emailParam, tokenParam)
    }
  }, [emailParam, verifyEmail, tokenParam])

  const onSubmit = (event) => {
    event.preventDefault()
    if (!email) {
      setInvalidEmail(true)
      return
    }
    setInvalidEmail(false)
    signIn(email).catch(error => {
      console.log(error)
      setInvalidEmail(true)
    })
  }

  const onChange = useCallback((event) => {
    if (invalidEmail) setInvalidEmail(false)
    setEmail(event.target.value)
  }, [])

  return (
    <form noValidate onSubmit={onSubmit} className={classes.root}>
      <Logo className={classes.logo} />
      <Paper elevation={4} className={classes.paper}>
        <Typography variant='h6' className={classes.halfMarginBottom}>{inEmail ? 'Check your email' : 'Sign in with your BASH email'}</Typography>
        {inEmail &&
          <Typography variant='body2'>You should have received a link in your inbox. Click it to sign in to your account.</Typography>}
        {!inEmail && !submitting && (
          <>
            <TextField
              className={classes.input} autoFocus onChange={onChange} value={email} color='secondary'
              label='Email address' placeholder='john.doe@mail.com' InputLabelProps={{ shrink: true }}
              type='email' error={invalidEmail} helperText={invalidEmail ? 'Invalid Email address' : ''}
            />
            <FlatButton
              className={classes.submit}
              loading={requesting && !invalidEmail}
              color='secondary'
              type='submit'
            >
              Continue
            </FlatButton>
          </>
        )}
        {submitting &&
          <CircularProgress className={classes.loader} color='primary' />}
      </Paper>
    </form>
  )
}

const mapStateToProps = ({ auth }) => ({
  requesting: auth.requesting,
  submitting: auth.submitting,
  inEmail: auth.inEmail
})

export default connect(mapStateToProps, { signIn, verifyEmail })(withStyles(styles)(SignIn))
