import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import apiClient from '../api/ApiClient'
import FeedEvent from '../components/Feed/FeedEvent'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 40
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  usersLabel: {
    marginBottom: theme.spacing(2)
  },
  switchContainer: {
    display: 'flex'
  },
  titleContainer: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  settingsHeader: {
    marginTop: 24,
    marginBottom: 16
  },
  eventContainer: {
    marginTop: 40
  },
  button: {
    marginTop: 16
  }
}))

const BooleanSwitch = ({ event, patchDto, setPatchDto, patchKey, name }) => (
  <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
    <Typography style={{ marginRight: 16 }}>{name}
    </Typography>
    <Switch
      checked={patchDto[patchKey] ?? event[patchKey]}
      onChange={e => setPatchDto({
        ...patchDto,
        [patchKey]: e.target.checked
      })}
    />
  </div>
)

const EventManagement = () => {
  const classes = useStyles()
  const [eventCode, setEventCode] = useState('')
  const [eventId, setEventId] = useState('')
  const [event, setEvent] = useState(null)
  const dispatch = useDispatch()

  const [patchDto, setPatchDto] = useState({})

  const fetchEvent = async () => {
    const newEvent = await apiClient.events.getAsAdmin(eventId, eventCode)
    setEvent(newEvent)
  }

  const patchEvent = async () => {
    const newEvent = await apiClient.events.patch(event.id, patchDto)
    setEvent(newEvent)
  }

  console.log(patchDto)

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Select an event
          </Typography>
        </div>
        <div className={classes.switchContainer}>
          <TextField
            className={classes.textInput}
            onChange={e => setEventCode(e.target.value)}
            value={eventCode}
            label='Event Code'
            variant='outlined'
          />
          <Button onClick={fetchEvent}>GET</Button>
        </div>
        <div className={classes.switchContainer}>
          <TextField
            className={classes.textInput}
            onChange={e => setEventId(e.target.value)}
            value={eventId}
            label='Event ID'
            variant='outlined'
            type='number'
          />
          <Button onClick={fetchEvent}>GET</Button>
        </div>
        {event && (
          <div className={classes.eventContainer}>
            <FeedEvent event={event} />
            <Typography variant='h3' className={classes.settingsHeader}>
              Settings
            </Typography>
            <BooleanSwitch
              patchKey='alwaysIncludeInRecommendations'
              name='Always include in recommendations'
              {...{ event, patchDto, setPatchDto }}
            />
            <BooleanSwitch
              patchKey='excludeFromRecommendations'
              name='Exclude from recommendations'
              {...{ event, patchDto, setPatchDto }}
            />
            <Button className={classes.button} color='primary' variant='contained' onClick={patchEvent}>SAVE</Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default EventManagement
