import RestClient from './RestClient'

const imageSizes = {
  default: {
    imageSizesEvent: 'cardSm',
    imageSizesAvatar: 'sm',
    imageSizesOrgAvatar: 'sm',
    imageSizesMessages: ''
  },
  profile: {
    imageSizesEvent: 'cardSm',
    imageSizesAvatar: 'lg',
    imageSizesOrgAvatar: 'sm',
    imageSizesMessages: ''
  }
}

class ApiClient extends RestClient {
  constructor (baseUrl) {
    super(baseUrl, {})
  }

  admin = {
    getMe: () => this.GET('/admin'),
    signIn: email => this.GET('/admin/signIn', { email }),
    submitEmailToken: (email, token) => this.GET(`/admin/signIn/${email}/${token}`),
    signOut: () => this.GET('/admin/signOut'),
    getUserFeed: (userId, lastValue, limit) => this.GET(`/admin/feed/${userId}`, { lastValue, limit }),
    getUserFeedSuggestions: (userId, page, size) => this.GET(`/admin/feedSuggestions/${userId}`, { page, size }),
    getUserNotifications: (userId, page, size) => this.GET(`/admin/notifications/${userId}`, { page, size }),
    getUserFollowing: (userId, page, size) => this.GET(`/admin/following/${userId}`, { page, size }),
    recalculateScores: (userIds) => this.POST('/admin/feed/scores/update/personal', { userIds }),
    getEventScoreReport: (eventId, fromUserId, untilUserId) => this.POST('/admin/feed/scores/report/event', { eventId, fromUserId, untilUserId }),
    getUserScoreReport: (userId) => this.GET(`/admin/feed/scores/report/users/${userId}`)
  }

  featuredItems = {
    get: (limit, lastValue) => this.GET('/admin/featuredItems', { limit, lastValue }),
    getUploadUrl: (id, format) => this.GET(`/admin/featuredItems/${id}/imageUploadUrl`, { format }),
    post: (featuredItem) => this.POST('/admin/featuredItems', featuredItem),
    patch: (featuredItem) => this.PATCH('/admin/featuredItems', featuredItem),
    delete: (id) => this.DELETE(`/admin/featuredItems/${id}`)
  }

  stats = {
    get: () => {
      return this.GET('/admin/stats')
    }
  }

  users = {
    findByEmailAddress: (emailAddress) => this.GET('/admin/users/findByEmailAddress', { emailAddress }),
    delete: (id) => this.DELETE(`/admin/users/${id}`),
    get: (limit, lastValue) => {
      return this.GET('/admin/users', { limit, lastValue, ...imageSizes.default })
    },
    getProfile: (userId) => this.GET(`/users/${userId}/profile`, { ...imageSizes.profile }),
    getById: (userId) => this.GET(`/admin/users/${userId}`, { ...imageSizes.default }),
    patch: (userId, user) => this.PATCH(`/admin/users/${userId}`, user)
  }

  onlineUsers = {
    get: (limit, lastValue) => {
      return this.GET('/admin/onlineUsers', { limit, lastValue, ...imageSizes.default })
    }
  }

  events = {
    get: (limit, lastValue) => {
      return this.GET('/admin/events', { limit, lastValue, ...imageSizes.default })
    },
    getOrg: (organisationId, limit, lastValue) => {
      return this.GET('/admin/events', { organisationId, limit, lastValue, ...imageSizes.default })
    },
    comingUp: (limit, lastValue) => {
      return this.GET('/admin/events/comingUp', { limit, lastValue, ...imageSizes.default })
    },
    patch: (id, event) => this.PATCH(`/admin/events/${id}`, event),
    getById: (id) => this.GET(`/events/${id}`, { ...imageSizes.default, imageSizesEvent: 'md' }),
    getByCode: (eventCode) => this.GET('/code/events', { eventCode, ...imageSizes.default, imageSizesEvent: 'md' }),
    getAsAdmin: (id, eventCode) => this.GET('/admin/events/find', { id, eventCode, ...imageSizes.default, imageSizesEvent: 'md' }),
    setScoreBoost: (id, scoreBoost) => this.POST(`/admin/events/${id}/scoreBoost`, scoreBoost)
  }

  guests = {
    get: (limit, lastValue, sortBy) => {
      return this.GET('/admin/guests', { limit, lastValue, sortBy, ...imageSizes.default })
    }
  }

  categories = {
    get: () => this.GET('/categories')
  }

  organisations = {
    get: (limit, lastValue, sortBy, managedByUsers = false) => {
      return this.GET('/admin/onlineOrganisations', { limit, lastValue, sortBy, managedByUsers, ...imageSizes.default })
    },
    patch: (id, organisation) => {
      return this.PATCH(`/admin/organisations/${id}`, organisation)
    },
    findByUsernameOrName: (username, name) => this.GET('/admin/organisations/find', { username, name, imageSizesOrgAvatar: 'xl' }),
    getAvatarUploadUrl: (username, format) => this.GET('/admin/organisations/avatarUploadUrl', { username, format }),
    getFollowers: (organisationId, page, size) => this.GET(`/organisations/${organisationId}/followers`, { page, size }),
    getFees: (organisationId) => this.GET(`/organisationsAdmin/${organisationId}/orgFee`),
    addFee: (organisationId, fee) => this.POST(`/organisationsAdmin/${organisationId}/orgFee`, fee),
    patchFee: (organisationId, feeId, fee) => this.PATCH(`/organisationsAdmin/${organisationId}/orgFee/${feeId}`, fee),
    deleteFee: (organisationId, feeId) => this.DELETE(`/organisationsAdmin/${organisationId}/orgFee/${feeId}`),
    getAvgTicketPrice: (organisationId) => this.GET(`/organisationsAdmin/${organisationId}/avgTicketPrice`),
    addAvgTicketPrice: (organisationId, price) => this.POST(`/organisationsAdmin/${organisationId}/avgTicketPrice`, price),
    patchAvgTicketPrice: (organisationId, priceId, price) => this.PATCH(`/organisationsAdmin/${organisationId}/avgTicketPrice/${priceId}`, price),
    deleteAvgTicketPrice: (organisationId, priceId) => this.DELETE(`/organisationsAdmin/${organisationId}/avgTicketPrice/${priceId}`)
  }

  refresh = {
    headerImages: () => {
      return this.POST('/admin/refresh/headerImages')
    }
  }

  notifications = {
    sendMixpanelNotification: (body) => this.POST('/admin/mixpanelNotification', body)
  }

  crawledEvents = {
    getPending: (page, size) => this.GET('/admin/events/crawl/pending', { page, size }),
    getApproved: (page, size) => this.GET('/admin/events/crawl/approved', { page, size }),
    approve: (id) => this.GET(`/admin/events/crawl/${id}`),
    delete: (id) => this.DELETE(`/admin/events/crawl/${id}`),
    create: (event) => this.POST('/admin/events/crawl', event)
  }

  reports = {
    getByState: (page, size, state) => this.GET('/admin/reports/state', { page, size, state }),
    patchState: (id, state) => this.PATCH(`/admin/reports/${id}/state`, { state })
  }
}

const client = new ApiClient(process.env.REACT_APP_API_HOST)
Object.freeze(client)

export default client
