import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import LoadingScreen from '../LoadingScreen'
import { history } from '../../config/store'

export default function (ComposedComponent) {
  const loaderStyles = theme => ({
    loaderRoot: {
      display: 'flex',
      height: '100%',
      width: '100%',
      position: 'absolute',
      flexDirection: 'column',
      top: 0,
      background: theme.palette.primary.main,
      alignItems: 'center',
      justifyContent: 'center'
    },
    loader: {
      marginTop: theme.spacing(2)
    }
  })

  const Authentication = ({ classes, retrieving, user, ...other }) => {
    useEffect(() => {
      if (!retrieving && !user) {
        history.push('/signIn')
      }
    }, [retrieving, user])

    if (user) {
      return <ComposedComponent {...other} />
    } else {
      return <LoadingScreen />
    }
  }

  const mapStateToProps = ({ user }) => ({
    user: user.value,
    retrieving: user.loading
  })

  return connect(mapStateToProps)(withStyles(loaderStyles)(Authentication))
}
