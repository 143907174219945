import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { fetchOnlineOrganisations, refreshHeaderImagesCache } from '../actions'
import apiClient from '../api/ApiClient'
import FeaturedItem from '../components/FeaturedItem'

const styles = theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  addButton: {
    margin: theme.spacing(3, 0)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
})

const FeaturedItems = ({ classes }) => {
  const [featuredItems, setFeaturedItems] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchFeaturedItems = async () => {
    setLoading(true)
    const response = await apiClient.featuredItems.get(100, null)
    setFeaturedItems(response.data)
    setLoading(false)
  }

  const addItem = () => {
    setFeaturedItems([...featuredItems, {}])
  }

  useEffect(() => {
    fetchFeaturedItems()
  }, [])

  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item xs={12}>
        <div className={classes.container}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Featured Items
          </Typography>
          {loading &&
            <Typography variant='body1' className={classes.usersLabel}>
              Loading...
            </Typography>}
          {featuredItems.map(fi => (
            <FeaturedItem key={fi.id} featuredItem={fi} reloadPage={fetchFeaturedItems} />
          ))}
          <Button className={classes.addButton} onClick={addItem} variant='outlined' color='primary'>
            Add Featured Item
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

function mapStateToProps () {
  return {}
}

export default connect(mapStateToProps, { fetchOnlineOrganisations, refreshHeaderImagesCache })(withStyles(styles)(FeaturedItems))
