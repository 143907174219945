import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Paper, Typography, withStyles } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import UserIcon from '../media/tooltip-account.svg'
import EmailIcon from '../media/email.svg'
import ShareIcon from '../media/share.svg'
import CalendarIcon from '../media/calendar-check.svg'
import BoltIcon from '../media/bolt.svg'
import DownloadIcon from '../media/download.svg'
import * as classNames from 'classnames'
import { connect } from 'react-redux'
import { fetchGuests } from '../actions'
import useKeysetPagination from '../hooks/useKeysetPagination'
import apiClient from '../api/ApiClient'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const styles = theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  usersItem: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: 56,
    overflowX: 'auto'
  },
  usersLabel: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit
  },
  tableCellRoot: {
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    maxWidth: '150px'
  },
  nameCell: {
    cursor: 'pointer'
  },
  cellIcon: {
    verticalAlign: 'bottom',
    marginRight: theme.spacing.unit * 2
  },
  avatar: {
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
    borderRadius: 1000,
    verticalAlign: 'bottom',
    marginRight: theme.spacing.unit * 2
  },
  shiftDown: {
    bottom: -2,
    position: 'relative'
  }
})

const Guests = ({ classes }) => {
  const [sortBy, setSortBy] = useState('UPDATED_AT')
  const { data: guests, tablePaginationEl, reset } = useKeysetPagination(
    (limit, lastValue) => apiClient.guests.get(limit, lastValue, sortBy),
    guest => sortBy === 'UPDATED_AT' ? guest.updatedAt : guest.createdAt
  )

  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item xs={12}>
        <Paper elevation={2} className={classes.usersItem}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Guests updated within a week ({guests.length})
          </Typography>
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel>Sort by</InputLabel>
            <Select
              value={sortBy}
              onChange={e => { setSortBy(e.target.value); reset() }}
              label='Sort By'
            >
              <MenuItem value='UPDATED_AT'>Updated</MenuItem>
              <MenuItem value='CREATED_AT'>Created at</MenuItem>
            </Select>
          </FormControl>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.tableCellRoot }}>Guest</TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>Event (ID)</TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>State</TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>Status</TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>Updated</TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>Created</TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>Activity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {guests.map(guest => (
                <TableRow key={guest.id}>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }} component='th' scope='row'
                  >
                    <a target='_blank' href={process.env.REACT_APP_API_HOST + guest.avatarUrl} rel='noreferrer'>
                      <img
                        className={classes.avatar}
                        src={guest.avatarUrls.sm}
                      />
                    </a>
                    {guest.name}
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }} className={classes.nameCell}
                    onClick={() => window.open(process.env.REACT_APP_RSVP_LINK + guest.event.code + '?v=Test')}
                  >
                    {guest.event.name} ({guest.event.id})
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {guest.state}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {guest.status}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {moment(guest.updatedAt).format('dd DD MMM HH:mm')}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {moment(guest.createdAt).format('dd DD MMM HH:mm')}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {guest.hasUser &&
                      <Tooltip title='Is user' aria-label='Is user'>
                        <span>
                          <img src={UserIcon} className={classNames(classes.cellIcon, classes.shiftDown)} />
                        </span>
                      </Tooltip>}
                    {guest.hasEmailAddress &&
                      <Tooltip title='Has email address' aria-label='Has email address'>
                        <span>
                          <img src={EmailIcon} className={classNames(classes.cellIcon, classes.shiftDown)} />
                        </span>
                      </Tooltip>}
                    {!guest.invitedByOrganiser &&
                      <Tooltip title='Added themselves' aria-label='Added themselves'>
                        <span>
                          <img src={ShareIcon} className={classNames(classes.cellIcon, classes.shiftDown)} />
                        </span>
                      </Tooltip>}
                    {guest.addedToCalendar &&
                      <Tooltip title='Added to calendar' aria-label='Added to calendar'>
                        <span>
                          <img src={CalendarIcon} className={classNames(classes.cellIcon, classes.shiftDown)} />
                        </span>
                      </Tooltip>}
                    {guest.hasNotificationToken &&
                      <Tooltip title='Has a notification token' aria-label='Has a notification token'>
                        <span>
                          <img src={BoltIcon} className={classNames(classes.cellIcon, classes.shiftDown)} />
                        </span>
                      </Tooltip>}
                    {guest.pressedDownload &&
                      <Tooltip title='Pressed download' aria-label='Pressed download'>
                        <span>
                          <img src={DownloadIcon} className={classNames(classes.cellIcon, classes.shiftDown)} />
                        </span>
                      </Tooltip>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {tablePaginationEl}
        </Paper>
      </Grid>
    </Grid>
  )
}

function mapStateToProps ({ data }) {
  return {}
}

export default connect(mapStateToProps, { fetchGuests })(withStyles(styles)(Guests))
