import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, Typography, withStyles, TextField, Paper, Switch } from '@material-ui/core'
import apiClient from '../api/ApiClient'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'

const styles = theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  addButton: {
    marginTop: theme.spacing(3)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(3)
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  textInput: {
    marginTop: theme.spacing(2)
  },
  switch: {
    margin: theme.spacing(2, 0)
  }
})

const isValidHttpUrl = (string) => {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

const Notifications = ({ classes }) => {
  const [cohortId, setCohortId] = useState('')
  const [save, setSave] = useState(false)
  const [translations, setTranslations] = useState({})

  const [clickAction, setClickAction] = useState('')
  const [iconUrl, setIconUrl] = useState('')

  const [currentLocale, setCurrentLocale] = useState('en')

  const [errors, setErrors] = useState({})
  const [postMessage, setPostMessage] = useState(null)

  const [loading, setLoading] = useState(false)

  const setDescription = (event) => {
    setTranslations({
      ...translations,
      [currentLocale]: {
        ...translations[currentLocale],
        description: event.target.value
      }
    })
  }

  const setTitle = (event) => {
    setTranslations({
      ...translations,
      [currentLocale]: {
        ...translations[currentLocale],
        title: event.target.value
      }
    })
  }

  const validateAndSubmit = async () => {
    setLoading(true)
    const error = {}
    if (isNaN(parseInt(cohortId))) {
      error.cohortId = 'Must be a valid integer'
    }
    if (translations.en.title.trim() === '') {
      error.title = 'Title cannot be blank'
    }
    if (translations.en.description.trim() === '') {
      error.description = 'Description cannot be blank'
    }
    if (iconUrl !== '' && !isValidHttpUrl(iconUrl)) {
      error.iconUrl = 'Invalid url'
    }
    if (Object.keys(error).length) {
      setErrors(error)
      setLoading(false)
      return
    }
    try {
      await apiClient.notifications.sendMixpanelNotification({
        cohortId: parseInt(cohortId),
        notification: {
          save,
          translations,
          clickAction: clickAction.trim() === '' ? null : clickAction,
          iconUrl: iconUrl.trim() === '' ? null : iconUrl
        }
      })
      setPostMessage('Notification sent!')
    } catch (e) {
      setPostMessage(e.toString())
    }
    setLoading(false)
  }

  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item xs={12}>
        <Paper className={classes.container}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Notifications
          </Typography>
          <div className={classes.inputsContainer}>
            <TextField
              className={classes.textInput}
              onChange={(e) => setCohortId(e.target.value)}
              value={cohortId}
              error={errors.cohortId}
              helperText={errors.cohortId}
              label='Cohort ID'
              variant='outlined'
            />
            <FormControlLabel
              className={classes.switch}
              control={
                <Switch
                  onChange={(e) => setSave(e.target.checked)}
                  value={save}
                />
              }
              label='Save to activity'
            />
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel>Locale</InputLabel>
              <Select
                value={currentLocale}
                onChange={e => setCurrentLocale(e.target.value)}
                label='Language'
              >
                <MenuItem value='en'>English</MenuItem>
                <MenuItem value='nl'>Dutch</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className={classes.textInput}
              onChange={setTitle}
              value={translations[currentLocale]?.title ?? ''}
              error={errors.title}
              helperText={errors.title}
              label='Title'
              variant='outlined'
            />
            <TextField
              className={classes.textInput}
              onChange={setDescription}
              value={translations[currentLocale]?.description ?? ''}
              error={errors.description}
              helperText={errors.description}
              label='Description'
              variant='outlined'
            />
            <TextField
              className={classes.textInput}
              onChange={(e) => setClickAction(e.target.value)}
              value={clickAction}
              label='Click Action'
              variant='outlined'
            />
            <TextField
              className={classes.textInput}
              onChange={(e) => setIconUrl(e.target.value)}
              value={iconUrl}
              error={errors.iconUrl}
              helperText={errors.iconUrl}
              label='Icon URL'
              variant='outlined'
            />
          </div>
          {postMessage && <Typography variant='subtitle1'>{postMessage}</Typography>}
          <Button className={classes.addButton} onClick={validateAndSubmit} disabled={loading} variant='outlined' color='primary'>
            Send Notification
          </Button>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Notifications)
