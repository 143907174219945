import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import moment from 'moment'
import React, { Component } from 'react'
import AndroidIcon from '../media/android.svg'
import AppleIcon from '../media/apple.svg'
import Tooltip from '@material-ui/core/Tooltip'
import CakeIcon from '../media/cake.svg'
import CalendarEditIcon from '../media/calendar-edit.svg'
import classNames from 'classnames'
import TooltipAccountIcon from '../media/tooltip-account.svg'
import MessageReplyIcon from '../media/message-reply.svg'
import FriendIcon from '../media/account-heart.svg'
import FollowingIcon from '../media/list-check.svg'
import NotNotifyableIcon from '../media/not-notifyable.svg'
import Table from '@material-ui/core/Table'
import { connect, useDispatch } from 'react-redux'
import { Button, withStyles } from '@material-ui/core'
import s from 'underscore.string'
import { history } from '../config/store'
import { openUserProfile } from '../actions'

const styles = theme => ({
  usersItem: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.06), 0px 8px 10px 1px rgba(0,0,0,0.04), 0px 3px 14px 2px rgba(0,0,0,0.04)',
    marginBottom: 56,
    backgroundColor: '#f5f5f5',
    overflowX: 'auto'
  },
  usersLabel: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit
  },
  tableCellRoot: {
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    '& img': {
      verticalAlign: 'middle'
    }
  },
  cellIcon: {
    verticalAlign: 'bottom',
    marginRight: theme.spacing.unit * 1
  },
  avatar: {
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
    borderRadius: 1000,
    verticalAlign: 'bottom',
    marginRight: theme.spacing.unit * 2
  }
})

const UserList = ({ users, classes }) => {
  const dispatch = useDispatch()

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell classes={{ root: classes.tableCellRoot }}>Name (ID)</TableCell>
          <TableCell classes={{ root: classes.tableCellRoot }}>Phone / Email</TableCell>
          <TableCell classes={{ root: classes.tableCellRoot }}>State</TableCell>
          <TableCell classes={{ root: classes.tableCellRoot }}>Online</TableCell>
          <TableCell classes={{ root: classes.tableCellRoot }}>Signed up</TableCell>
          <TableCell classes={{ root: classes.tableCellRoot }}>Activity </TableCell>
          <TableCell classes={{ root: classes.tableCellRoot }}>Profile</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map(user => {
          let phoneNumber
          let smsCode
          if (user.phoneNumber === null || user.phoneNumber === '' || user.phoneNumber === undefined) {
            phoneNumber = user.newPhoneNumber
            smsCode = user.smsCode
          } else {
            phoneNumber = user.phoneNumber
          }
          let emailAddress
          let emailCode
          if (user.emailAddress === null || user.emailAddress === '' || user.emailAddress === undefined) {
            emailAddress = user.newEmailAddress
            emailCode = user.emailCode
          } else {
            emailAddress = user.emailAddress
          }
          if (emailAddress && emailAddress.includes('privaterelay')) emailAddress = 'Private'
          let birthday
          if (user.birthday !== undefined) {
            birthday = moment(user.birthday).format('DD MMM')
          }
          return (
            <TableRow key={user.id}>
              <TableCell classes={{ root: classes.tableCellRoot }} component='th' scope='row'>
                <a target='_blank' href={process.env.REACT_APP_API_HOST + user.avatarUrl} rel='noreferrer'>
                  <img
                    className={classes.avatar}
                    src={user.avatarUrls.sm}
                  />
                </a>
                {user.name} ({user.id})
              </TableCell>
              {user.phoneNumber ?
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  ({user.countryCode}) {phoneNumber}
                  {smsCode && <div>NEW:({smsCode})</div>}
                </TableCell> :
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {emailAddress}
                  {emailCode && <div>NEW:({emailCode})</div>}
                </TableCell>}
              <TableCell classes={{ root: classes.tableCellRoot }}>
                <img className={classes.cellIcon} src={user.deviceType === 'ANDROID' ? AndroidIcon : user.deviceType === 'IOS' ? AppleIcon : null} />
                {user.state !== 'ACTIVE' && s.capitalize(user.state, true)}
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot }}>
                {moment(user.lastOnline).format('HH:mm')}
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot }}>
                {moment(user.createdAt).format('dd D MMM HH:mm')}
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot }}>
                {user.birthday !== undefined &&
                  <Tooltip title={birthday} aria-label={birthday}>
                    <img src={CakeIcon} className={classes.cellIcon} />
                  </Tooltip>}
                {user.numberOfEventsAsOrganiser !== 0 &&
                  <Tooltip title='Events as organiser' aria-label='Events as organiser'>
                    <span>
                      <b>{user.numberOfEventsAsOrganiser}</b>
                      <img src={CalendarEditIcon} className={classNames(classes.cellIcon)} />
                    </span>
                  </Tooltip>}
                {user.numberOfEventsAsGuest !== 0 &&
                  <Tooltip title='Events as guest' aria-label='Events as guest'>
                    <span>
                      <b>{user.numberOfEventsAsGuest}</b>
                      <img src={TooltipAccountIcon} className={classNames(classes.cellIcon)} />
                    </span>
                  </Tooltip>}
                {user.numberOfMessagesPosted !== 0 &&
                  <Tooltip title='Messages posted' aria-label='Messages posted'>
                    <span>
                      <b>{user.numberOfMessagesPosted}</b>
                      <img src={MessageReplyIcon} className={classNames(classes.cellIcon)} />
                    </span>
                  </Tooltip>}
                {user.numberOfFriends !== 0 &&
                  <Tooltip title='Number of friends' aria-label='Number of friends'>
                    <span>
                      <b>{user.numberOfFriends}</b>
                      <img src={FriendIcon} className={classNames(classes.cellIcon)} />
                    </span>
                  </Tooltip>}
                {user.numberFollowing !== 0 &&
                  <Tooltip title='Number following' aria-label='Number following'>
                    <span>
                      <b>{user.numberFollowing}</b>
                      <img src={FollowingIcon} className={classNames(classes.cellIcon)} />
                    </span>
                  </Tooltip>}
                {user.state === 'ACTIVE' && !user.notifyable &&
                  <Tooltip title='Notifications not enabled' aria-label='Notifications not enabled'>
                    <span>
                      <img src={NotNotifyableIcon} className={classNames(classes.cellIcon)} />
                    </span>
                  </Tooltip>}
              </TableCell>
              <TableCell>
                <Button
                  color='primary'
                  onClick={() => dispatch(openUserProfile(user.id))}
                >
                  Profile
                </Button>
              </TableCell>
            </TableRow>
          )
        }
        )}
      </TableBody>
    </Table>
  )
}

export default connect(null, null)(withStyles(styles)(UserList))
