import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Paper, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { fetchUsers } from '../actions'
import UserList from '../components/UserList'
import useKeysetPagination from '../hooks/useKeysetPagination'
import apiClient from '../api/ApiClient'

const styles = theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  usersItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: 56,
    overflowX: 'auto'
  },
  usersLabel: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing.unit
  },
  tableCellRoot: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  cellIcon: {
    verticalAlign: 'bottom',
    marginRight: theme.spacing(2)
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: 1000,
    verticalAlign: 'bottom',
    marginRight: theme.spacing(2)
  },
  shiftDown: {
    bottom: -2,
    position: 'relative'
  }
})

const Users = ({ classes }) => {
  const { data: users, tablePaginationEl } = useKeysetPagination(apiClient.users.get, user => user.createdAt)
  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item xs={12}>
        <Paper elevation={2} className={classes.usersItem}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            New Users:
          </Typography>
          <UserList users={users} />
          {tablePaginationEl}
        </Paper>
      </Grid>
    </Grid>
  )
}

function mapStateToProps ({ data, user }) {
  return {
    users: data.users,
    authString: user.authString
  }
}

export default connect(mapStateToProps, { fetchUsers })(withStyles(styles)(Users))
