import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import dayjs from 'dayjs'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  hostsContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
    padding: theme.spacing(0, 2, 2, 2)
  },
  title: {
    margin: theme.spacing(1, 0)
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whitespace: 'pre-line',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box'
  },
  infoContainer: {
    padding: theme.spacing(2, 2, 0, 2)
  },
  friendsAttendingContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(1)
  },
  imageColorYellow: {
    position: 'relative',
    background: '#FFFEE0',
    height: '200px'
  },
  imageColorGreen: {
    background: '#E0F2F0',
    height: '200px',
    position: 'relative'
  },
  emoji: {
    fontSize: '40px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
  },
  image: {
    maxWidth: 320,
    maxHeight: 240,
    objectFit: 'cover',
    marginLeft: 16
  }
}))

const FeedEvent = ({ event, style }) => {
  const classes = useStyles()
  return (
    <div className={classes.root} style={style}>
      <div className={classes.hostsContainer}>
        <Typography variant='body2'>
          Hosts:
        </Typography>
        {event.hosts?.map((host, index) => (
          <Typography key={host.id} variant='body2'>
            <b>
              {host.model?.user?.firstName ?? host.model?.user?.name ?? host.model?.name}
              {index !== event.hosts.length - 1 && ','}
            </b>
          </Typography>
        ))}
      </div>
      {event.imageUrls && (
        <img alt='event image' src={event.imageUrls?.md} className={classes.image} />
      )}
      {!event.imageUrls && (
        <div className={event.privacyType === 'PUBLIC' ? classes.imageColorYellow : classes.imageColorGreen}>
          <div className={classes.emoji}>
            {event.categoryObj.emoji}
          </div>
        </div>
      )}
      <div className={classes.infoContainer}>
        {event.startDate && <Typography variant='caption'>{dayjs(event.startDate).format('DD MMM HH:mm')}</Typography>}
        <Typography className={classes.title} variant='h5'>
          {event.name + ` (${event.id})`}
        </Typography>
        {event.description && (
          <Typography color='textSecondary' className={classes.description} variant='body2'>
            {event.description}
          </Typography>
        )}
        {event.friendsAttending?.length > 0 && (
          <div className={classes.friendsAttendingContainer}>
            {event.friendsAttending.map((friend, index) => (
              <Typography variant='caption' key={friend.id * index}>{(friend.firstName ?? friend.name) + (index === event.friendsAttending.length - 1 ? '' : ',')}</Typography>
            ))}
            {event.friendsAttendingCount > 2 && (
              <Typography variant='caption'>{' + ' + (event.friendsAttendingCount - 2)}</Typography>
            )}
            <Typography variant='caption'>going</Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default (FeedEvent)
