import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import parse from 'autosuggest-highlight/parse'
import _ from 'underscore'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: 64,
    display: 'flex',
    alignItems: 'center'
  }
})

// const TextInputBase = withStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.grey.main,
//     border: 'none',
//     borderRadius: 8,
//     width: '100%',
//     padding: theme.spacing(2),
//     fontSize: '1rem',
//     '& input': {
//       padding: 0
//     },
//     marginTop: theme.spacing(3)
//   }
// }))(InputBase)

const LocationAutoComplete = ({ classes, icon, inputValue, map, onChange, ...other }) => {
  const autocompleteService = { current: null }
  const [value, setValue] = useState(null)
  // const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([])

  useEffect(() => {
    console.log('in use effect location auto0 complete')
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }
    if (autocompleteService.current) {
      _.throttle(autocompleteService.current.getPlacePredictions({ input: inputValue }, (results) => {
        if (active) {
          let newOptions = []

          if (value) {
            newOptions = [value]
          }

          if (results) {
            newOptions = [...newOptions, ...results]
          }

          setOptions(newOptions)
        }
      }), 300)
    }

    return () => {
      active = false
    }
  }, [inputValue])

  const onOptionClick = (event, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options)
    setValue(newValue)
    if (map && newValue) {
      const pService = new window.google.maps.places.PlacesService(map)

      pService.getDetails({ placeId: newValue.place_id }, (results) => {
        onChange('coordinates', { type: 'POINT', coordinates: [results.geometry.location.lng(), results.geometry.location.lat()] })
        onChange('address', '')
      })
    }
  }

  return (
    <Autocomplete
      id='location-autocomplete'
      style={{ width: '100%' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      freeSolo
      filterSelectedOptions
      value={inputValue}
      onChange={onOptionClick}
      onInputChange={(event, newInputValue) => {
        onChange('location', newInputValue)
        onChange('address', '')
      }}
      renderInput={(params) => (
        <TextField
          style={{ width: '100%' }}
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          onFocus={(e) => e.currentTarget.select()}
          className={classes.textInput}
          label='location'
          variant='outlined'
        />
        // <TextInputBase
        //   ref={params.InputProps.ref}
        //   inputProps={params.inputProps}
        //   onFocus={(e) => e.currentTarget.select()}
        //   autoFocus
        // />
        // <TextInput ref={params.InputProps.ref} {...params} {...other} color="secondary" fullWidth />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        )

        return (
          <Grid container alignItems='center'>
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant='body2' color='textSecondary'>
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

export default withStyles(styles)(LocationAutoComplete)
