import React, { useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  CircularProgress, IconButton,
  TextField,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import apiClient from '../../api/ApiClient'
import InfiniteScroll from 'react-infinite-scroller'
import SuggestionItem from './SuggestionItem'
import { openUserProfile } from '../../actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '700px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  scrollContainer: {
    overflow: 'scroll'
  },
  titleContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  }
}))

const FEED_PAGE_SIZE = 10

const UserSuggestions = ({ userId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const ref = useRef()

  React.useEffect(() => {
    if (!userId) {
      setItems([])
      setHasMore(true)
    }
  }, [userId])

  const loadMore = async () => {
    if (userId !== null) {
      setLoading(true)
      const page = Math.floor(items.length / FEED_PAGE_SIZE)
      // dispatch(fetchActivity(page, FOLLOWER_PAGE_SIZE))
      const newItems = await apiClient.admin.getUserFeedSuggestions(userId, page, FEED_PAGE_SIZE)
      setHasMore(newItems.length === FEED_PAGE_SIZE)
      setItems(prev => [...prev, ...newItems])
      // eslint-disable-next-line promise/param-names
      setLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant='h4'>
          User feed suggestions
        </Typography>
        <Button color='primary' onClick={() => dispatch(openUserProfile(userId))}>
          Show profile
        </Button>
      </div>

      <div ref={ref} className={classes.scrollContainer}>
        <InfiniteScroll
          pageStart={-1}
          loadMore={loadMore}
          hasMore={((items.length / FEED_PAGE_SIZE) % 1 === 0 && !loading && hasMore)}
          threshold={250}
        >
          <div className={classes.itemContainer}>
            {items.map((item, index) => {
              return (
                <div key={index}>
                  <SuggestionItem item={item} />
                </div>
              )
            })}
            {loading && <CircularProgress />}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default UserSuggestions
