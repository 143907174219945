import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, Typography, withStyles } from '@material-ui/core'
import apiClient from '../api/ApiClient'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const styles = theme => ({
  root: {
    maxWidth: 500,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  addButton: {
    margin: theme.spacing(3, 0)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 1000
  },
  buttonsContainer: {
    display: 'flex',
    marginLeft: 'auto'
  },
  usersLabel: {
    marginBottom: theme.spacing(2)
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  pageButtonsContainer: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  textInput: {
    width: 100
  },
  formControl: {
    width: 200
  }
})

const state = [
  'PENDING',
  'RESOLVED',
  'REJECTED'
]

const StateSelect = ({ value, onChange }) => (
  <FormControl variant='outlined' style={{ width: 140 }}>
    <Select
      value={value}
      onChange={e => onChange(e.target.value)}
    >
      {state.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
    </Select>
  </FormControl>
)

const getTypeAndId = (report) => {
  if (report.user != null) return ['USER', report.user.id]
  if (report.message != null) return ['MESSAGE', report.message.id]
  if (report.event != null) return ['EVENT', report.event.id]
  return ['NONE', -1]
}

const Report = withStyles(styles)(({ classes, report, replaceInList, showState }) => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(report.state)

  const setStateApi = async () => {
    setLoading(true)
    const reportResponse = await apiClient.reports.patchState(report.id, state)
    replaceInList(reportResponse)
    setLoading(false)
  }

  const [type, id] = getTypeAndId(report)

  return (
    <div className={classes.eventContainer}>
      <Typography style={{ width: 60 }}>{report.id}</Typography>
      <Typography style={{ width: 500 }}>{report.description}</Typography>
      <Typography style={{ width: 90 }}>{type}</Typography>
      <Typography style={{ width: 90 }}>{id}</Typography>
      <Typography>{report.createdBy.id}</Typography>
      <div className={classes.buttonsContainer}>
        <StateSelect value={state} onChange={setState} />
        <Button disabled={loading} onClick={setStateApi}>SAVE</Button>
      </div>
    </div>
  )
})

const PAGE_SIZE = 15

const Crawled = ({ classes }) => {
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(false)

  const [showState, setShowState] = useState('PENDING')
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)

  const reportsOfPage = reports.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)

  const fetchCrawledEvents = async (page) => {
    setLoading(true)
    const response = await apiClient.reports.getByState(page, PAGE_SIZE, showState)
    setReports([...reports, ...response])
    if (response.length < PAGE_SIZE) setHasMore(false)
    setLoading(false)
  }

  const replaceInList = (id, report) => {
    setReports(reports.map(r => r.id === id ? report : r))
  }

  const onChangeShowState = (checked) => {
    setShowState(checked)
    setReports([])
    setHasMore(true)
    setPage(0)
  }

  useEffect(() => {
    fetchCrawledEvents(0)
  }, [showState])

  const nextPage = () => {
    setPage(page + 1)
    if (reports.length < (page + 2) * PAGE_SIZE) fetchCrawledEvents(page + 1)
  }

  return (
    <Grid className={classes.root} container spacing={16}>
      <Grid item xs={12}>
        <div className={classes.container}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Crawled events
          </Typography>
          <div className={classes.switchContainer}>
            <StateSelect value={showState} onChange={onChangeShowState} />
          </div>
          {loading &&
            <Typography variant='body1' className={classes.usersLabel}>
              Loading...
            </Typography>}
          <div className={classes.eventContainer}>
            <Typography style={{ width: 60 }}>ID</Typography>
            <Typography style={{ width: 500 }}>DESCRIPTION</Typography>
            <Typography style={{ width: 90 }}>TYPE</Typography>
            <Typography style={{ width: 90 }}>ID</Typography>
            <Typography style={{ width: 90 }}>REPORTED BY</Typography>
          </div>
          {reportsOfPage.map(report => (
            <Report key={report.id} report={report} replaceInList={replaceInList} showState={showState} />
          ))}
          <div className={classes.pageButtonsContainer}>
            <Button onClick={() => setPage(page - 1)} disabled={page < 1}>PREVIOUS</Button>
            <Button onClick={nextPage} disabled={!hasMore}>NEXT</Button>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Crawled)
