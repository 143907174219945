const NO_JSON_ERROR = { message: 'NO_JSON' }

export default class RestClient {
  constructor (baseUrl = '', { headers = {} } = {}) {
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    }
    this.baseUrl = baseUrl
  }

  _removeBlankAttr (obj) {
    Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key])
  }

  async _parseIfJson (response) {
    var contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json()
    } else if (+response.headers.get('content-length') === 0) {
      return
    }
    throw NO_JSON_ERROR
  }

  async _handleResponse (response) {
    const body = await this._parseIfJson(response)
    if (response.ok) {
      return body
    } else {
      throw body
    }
  }

  _fullRoute (url) {
    return `${this.baseUrl}${url}`
  }

  async _fetch (route, method, body, queryObj) {
    if (!route) throw new Error('Route is undefined')

    var fullRoute = this._fullRoute(route)
    if (queryObj) {
      var qs = require('qs')
      const query = qs.stringify(queryObj)
      fullRoute = `${fullRoute}?${query}`
    }

    const opts = {
      method,
      mode: 'cors',
      credentials: 'include',
      headers: this.headers
    }
    if (body) {
      this._removeBlankAttr(body)
    }
    // eslint-disable-next-line no-undef
    const promise = await fetch(fullRoute, body ? { ...opts, body: JSON.stringify((body)) } : opts)
    return this._handleResponse(promise)
  }

  GET (route, query) { return this._fetch(route, 'GET', null, query) }
  POST (route, body, query) { return this._fetch(route, 'POST', body, query) }
  PUT (route, body, query) { return this._fetch(route, 'PUT', body, query) }
  PATCH (route, body, query) { return this._fetch(route, 'PATCH', body, query) }
  DELETE (route, query) { return this._fetch(route, 'DELETE', null, query) }
}
