import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'
import { Email, Phone } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(2)
  },
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 100
  },
  title: {
    fontWeight: 500,
    maxWidth: 160,
    marginRight: theme.spacing(0.5)
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  textContainer: {
    marginRight: 'auto'
  }
}))

const UserProfile = ({ user }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <img alt='avatar' className={classes.avatar} src={user.avatarUrls.xl} />
      <div className={classes.textContainer}>
        <div className={classes.titleContainer}>
          <Typography noWrap className={classes.title} variant='body2'>{`${user.firstName} ${user.lastName}`}</Typography>
        </div>
      </div>
      <Email />
      <Typography variant='body2'>{user.emailAddress}</Typography>
      <Phone />
      <Typography variant='body2'>+{user.phoneNumber}</Typography>
    </Paper>
  )
}

export default UserProfile
