import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import apiClient from '../api/ApiClient'
import UserProfile from '../components/UserProfile/UserProfile'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 960,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 40
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  usersLabel: {
    marginBottom: theme.spacing(2)
  },
  switchContainer: {
    display: 'flex'
  },
  titleContainer: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  settingsHeader: {
    marginTop: 24,
    marginBottom: 16
  },
  dangerHeader: {
    marginTop: 96,
    marginBottom: 16
  },
  userContainer: {
    marginTop: 40
  },
  button: {
    marginTop: 16
  }
}))

const UserManagement = () => {
  const classes = useStyles()
  const [userId, setUserId] = useState('')
  const [user, setUser] = useState(null)
  const [deleting, setDeleting] = useState(false)

  const [patchDto, setPatchDto] = useState({
    notificationSettings: {
      doNotContact: false
    }
  })

  const setDoNotContact = (value) => {
    setPatchDto({
      ...patchDto,
      notificationSettings: {
        ...patchDto.notificationSettings,
        doNotContact: value
      }
    })
  }

  const fetchUser = async () => {
    const newUser = await apiClient.users.getById(userId)
    setUser(newUser)
    setDoNotContact(newUser.notificationSettings.doNotContact ?? false)
  }

  const patchUser = async () => {
    await apiClient.users.patch(user.id, patchDto)
    fetchUser()
  }

  const deleteUser = async () => {
    handleClose()
    setDeleting(true)
    await apiClient.users.delete(user.id)
    setDeleting(false)
    setUser(null)
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant='subtitle1' className={classes.usersLabel}>
            Select a user
          </Typography>
        </div>
        <div className={classes.switchContainer}>
          <TextField
            className={classes.textInput}
            onChange={e => setUserId(e.target.value)}
            value={userId}
            label='User ID'
            variant='outlined'
            type='number'
            onKeyUp={e => {
              if (e.key === 'Enter') {
                fetchUser()
              }
            }}
          />
          <Button onClick={fetchUser}>GET</Button>
        </div>
        {user && (
          <div className={classes.userContainer}>
            <UserProfile user={user} />
            <Typography variant='h3' className={classes.settingsHeader}>
              Settings
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <Typography style={{ marginRight: 16 }}>Do not contact
              </Typography>
              <Switch
                checked={patchDto.notificationSettings.doNotContact ?? false}
                onChange={e => setDoNotContact(e.target.checked)}
              />
            </div>
            <Button className={classes.button} color='primary' variant='contained' onClick={patchUser}>SAVE</Button>
            <Typography variant='h3' className={classes.dangerHeader} color='error'>
              DANGER ZONE
            </Typography>
            <Button color='secondary' variant='contained' onClick={handleClickOpen} disabled={deleting}>DELETE USER</Button>
            {deleting && <CircularProgress />}
          </div>
        )}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete user?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this user? THIS ACTION CANNOT BE UNDONE.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            CANCEL
          </Button>
          <Button onClick={deleteUser} color='primary' autoFocus>
            DELETE USER
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UserManagement
