import React from 'react'
import { withStyles } from '@material-ui/styles'
import { Button, CircularProgress } from '@material-ui/core'

const styles = theme => ({
  progress: {
    position: 'absolute',
    right: theme.spacing(2),
    color: 'white'
  }
})

const FlatButton = ({
  classes,
  color = 'primary',
  variant = 'contained',
  children,
  disabled,
  loading,
  disableElevation = true,
  ...otherProps
}) => {
  return (
    <Button
      disabled={disabled || loading}
      disableElevation={disableElevation}
      variant={variant}
      color={color}
      {...otherProps}
    >
      {children}
      {loading &&
        <CircularProgress
          size={24}
          color='inherit'
          className={classes.progress}
        />}
    </Button>
  )
}

export default withStyles(styles)(FlatButton)
